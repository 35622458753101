// @flow
export type MatchData = {
  matchValue: string,
  matchIndex: number,
  matchIndexEnd: number,
}

export type HighlightMatchedSubStringReturn = {
  matches: boolean,
  matchData: Array<MatchData>,
  value: string,
}

export type SuggestionMatches = Array<MatchData>

/**
 * Filters and removes overlapping regex matches for a given array.
 * @param {SuggestionMatches} matches Initial suggestion matches
 * @returns {SuggestionMatches} Filtered suggestion matches (remove overlaps)
 */
const formatOverlappingMatches = (matches: SuggestionMatches) => {
  const matchGroups = new Map([]);
  let prevIndexMatch = {
    matchValue: '',
    matchIndex: -1,
    matchIndexEnd: -1,
  };
  for (const match of matches) {
    if (match.matchIndex > prevIndexMatch.matchIndexEnd) {
      matchGroups.set(match.matchIndex, match);
      prevIndexMatch = { ...match };
    } else if (match.matchIndexEnd > prevIndexMatch.matchIndexEnd) {
      const startMatchStrIndex = prevIndexMatch.matchIndexEnd - match.matchIndex;
      const endMatchStrIndex = match.matchIndexEnd;
      const matchValue = match.matchValue.substr(startMatchStrIndex, endMatchStrIndex);
      prevIndexMatch = {
        matchValue: `${prevIndexMatch.matchValue}${matchValue}`,
        matchIndex: prevIndexMatch.matchIndex,
        matchIndexEnd: match.matchIndexEnd,
      };
      matchGroups.set(prevIndexMatch.matchIndex, prevIndexMatch);
    }
  }
  return [...matchGroups.values()];
};

/**
 * Helps us find the sub-string instances withing a given string
 * usefull for partial string matching and suggestion rendeering
 * @param {string} searchValue Sub-string value
 * @param {string} targetValue Target string
 * @returns {HighlightMatchedSubStringReturn} Matched payload
 */
export const highlightMatchedSubString = (searchValue: string, targetValue: string) => {
  const inputArr = searchValue.trim();
  if (inputArr.length !== 0) {
    let prevIndex = 0;
    let result = [];
    let allMatches = [];
    const inputSet = new Set(inputArr.split(' ').map(word => word.trim().toLowerCase()));
    const addSegment = (matchIndex, matchLength, segment) => {
      const textBefore = targetValue.substr(prevIndex, matchIndex - prevIndex);
      prevIndex = matchIndex + matchLength;
      if (textBefore.length !== 0) {
        result = [...result, textBefore, segment];
      } else {
        result = [...result, segment];
      }
    };
    for (const word of inputSet) {
      // Verify that a given string is a word.
      if (/\w+/gi.test(word)) {
        const wordFormatted = word.replace(/[\W_]+/gi, '');
        const matches = [...targetValue.matchAll(new RegExp(wordFormatted, 'gi'))];
        allMatches.push(...matches.map(match => ({
          matchValue: match[0],
          matchIndex: match.index,
          matchIndexEnd: match.index + match[0].length,
        })));
      }
    }
    allMatches.sort((a, b) => a.matchIndex - b.matchIndex);
    allMatches = formatOverlappingMatches(allMatches);

    for (const match of allMatches) {
      addSegment(match.matchIndex, match.matchValue.length, match);
    }
    if (result.length !== 0) {
      const textAfter = targetValue.substr(prevIndex, targetValue.length);
      if (textAfter.length !== 0) {
        return {
          matches: true,
          matchData: [...result, textAfter],
          value: targetValue,
        };
      }
      return {
        matches: true,
        matchData: result,
        value: targetValue,
      };
    }
  }
  return {
    matches: false,
    matchData: [],
    value: targetValue,
  };
};
