// @flow
/* eslint no-underscore-dangle: 0 */
/* eslint camelcase: 0 */
import React, { useEffect } from 'react';

import TypeHead from './TypeHead';
import ChatThread from './ChatThread/ChatThread';

// $flow-disable
import './ConversationBase.scss';

import { type ConversationBaseChatProps } from './ComponentEntities/componentEntities';

const ConversationBaseChat = (props: ConversationBaseChatProps) => {
  useEffect(() => {
    if (props.ready) {
      props.iframeInterface.sendCenteredNotification();
    }
  }, [props.ready]);

  const focusedClass = `g-conversation-base--${props.focused ? 'focused' : 'blurred'}`;
  const readyClass = `g-conversation-base--${props.ready ? 'ready' : ''}`;
  const backdropStyle_ = { ...props.backdropStyle, height: `${props.backdropStyle.height}px` };

  return (
    <div className={`g-conversation-base ${focusedClass} ${readyClass}`}>
      {props.welcomeMessages}
      <ChatThread
        analytics={props.analytics}
        hooks={props.hooks}
        botId={props.botId}
        settings={props.settings}
        conversation={props.conversation}
        conversationFocusedState={props.focused}
        ticketFields={props.ticketFields}
        iframeInterface={props.iframeInterface}
        parentHeight={props.parentHeight}
        parentWidth={props.parentWidth}
        setChatThreadRef={props.setChatThreadRef}
      />
      <TypeHead
        type="base"
        appId={props.appId}
        typeheadRef={props.typeheadRef}
        newMessageCount={props.newMessageCount}
        settings={props.settings}
        chatThreadRef={props.chatThreadRef}
        optionsRef={props.optionsRef}
        waitingForSystemResponse={props.waitingForSystemResponse}
        typeaheadSuggestions={props.typeaheadSuggestions}
        typeaheadCatalogSuggestions={props.typeaheadCatalogSuggestions}
        conversation={props.conversation}
        hadConversation={props.hadConversation}
        onError={props.onError}
        sendMessage={props.sendUserMessage}
        resetProactivePing={props.resetProactivePing}
        downloadTranscript={props.downloadTranscript}
        resetNewMessageCount={props.resetNewMessageCount}
        placeholder={props.placeholder}
        isLastMessageAQuestion={props.isLastMessageAQuestion}
        resetConversation={props.resetConversation}
        handleConversationFocusState={props.handleConversationFocusState}
        getTypeHeadCatalogSuggestions={props.getTypeHeadCatalogSuggestions}
        analytics={props.analytics}
      />
      <div className="g-app__backdrop" style={backdropStyle_} />
      <div
        id="g-no-display"
        ref={props.hiddenSmoochRef}
        style={{ display: 'none' }}
      />
      {props.renderResetCircle()}
    </div>
  );
};

export default ConversationBaseChat;
