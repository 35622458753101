// @flow
import React from 'react';

import QuotePulse from './loaders/QuotePulse';
import Options from './Options';

// $flow-disable
import './TypeHead.scss';

import { type TypeHeadBaseProps } from './ComponentEntities/componentEntities';

const TypeHeadBase = (props: TypeHeadBaseProps) => {
  const {
    focused,
    message,
    settings,
    newMessageCount,
    waitingForSystemResponse,
    placeholder,
    hadConversation,
    downloadTranscript,
    typeheadRef,
  } = props;
  return (
    <div
      className={`g-typehead g-typehead${focused ? '--focused' : '--blurred'}`}
      ref={props.nodeRef}
    >
      {props.renderAutoComplete()}
      <div className="g-typehead__logo-wrap">
        {settings.design.logo ? (
          props.renderCustomAvatar()
        ) : (
          <QuotePulse
            isLoading={waitingForSystemResponse}
            layout={settings.design.position}
            color={settings.design.highlightColor}
          />
        )}
      </div>
      <input
        aria-label="type-head-input"
        className="g-typehead__input"
        type="text"
        placeholder={placeholder}
        name="userMessage"
        value={message}
        autoComplete="off"
        ref={typeheadRef}
        onFocus={props.onFocus}
        onChange={props.onChange}
        onKeyUp={props.onKeyUp}
      />
      {props.renderAudioMic()}
      <div className="g-typehead__minimize-icon" onClick={props.handleBlur} />
      {newMessageCount ? props.updateUnreadMsgNumber(newMessageCount) : null}
      <div className="g-typehead__footer">
        <p className="powered-by">
          <span role="img" aria-label="lightbulb">
            💡
          </span>
          &nbsp; Powered by &nbsp;
          <a
            data-testid="gamalon-link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://gamalon.com/"
          >
            Gamalon
          </a>
        </p>
        <Options
          settings={settings}
          hadConversation={hadConversation}
          downloadTranscript={downloadTranscript}
        />
      </div>
    </div>
  );
};

export default TypeHeadBase;
