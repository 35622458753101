// @flow
import uuidv4 from 'uuid/v4';
import type { IdentityType } from './entities';

export const IdentityTrackingCommands = {
  SEND_IDENTITY_TO_PARENT: 'SEND_IDENTITY_TO_PARENT',
  IDENTIFY: 'IDENTIFY',
  SEND_VISITOR_ID_TO_IFRAME: 'SEND_VISITOR_ID_TO_IFRAME',
  SEND_SESSION_ID_TO_IFRAME: 'SEND_SESSION_ID_TO_IFRAME',
  READY: 'READY',
  CLEAR_STORAGE: 'CLEAR_STORAGE',
  STORAGE_CLEARED: 'STORAGE_CLEARED',
};

/** This class provides interface for tracking visitor identity via iframe */
export class IdentityTracker {
  organization: string;
  identifiedVisitorData: Object | null;
  src: string;
  iframeReady: boolean;
  identifyCallback: Function;
  clearStorageCallback: () => void;
  botId: number;
  iframeElement: HTMLIFrameElement | null;
  domain: string;
  crossSiteDisabled: boolean;

  /**
  * @param {string} organization - bot organization
  * @param {string} dekuDomain - domain for bot
  * @param {number} botId - application id
  */
  constructor(organization: string, dekuDomain: string, botId: number) {
    this.organization = organization;
    this.identifiedVisitorData = null;
    this.src = `${dekuDomain}/deku/apps/${botId}/identity`;
    this.iframeReady = false;
    this.identifyCallback = () => {};
    this.clearStorageCallback = () => {};
    this.botId = botId;
    this.domain = dekuDomain;
    this.iframeElement = null;
    this.crossSiteDisabled = false;

    // IdentityTracker communicates to the iframe via the 'message' event API.
    window.addEventListener('message', ({ data: { source, cmd, data } }) => {
      if (source === 'gamalon-identity') {
        switch (cmd) {
          case IdentityTrackingCommands.READY:
            this.iframeReady = true;
            break;
          case IdentityTrackingCommands.SEND_IDENTITY_TO_PARENT:
            this.identifiedVisitorData = {
              visitorId: data.visitorId,
              sessionId: data.sessionId,
            };
            this.identifyCallback(this.identifiedVisitorData);
            break;
          case IdentityTrackingCommands.STORAGE_CLEARED:
            this.clearStorageCallback();
            break;
          default:
            break;
        }
      }
    });
  }

  /**
   * Disables cross-site tracking. Call before doing any other initialization.
   */
  disableCrossDomainTracking() {
    this.crossSiteDisabled = true;
  }

  /**
  * @param {Functon | null} callback - callback that will be run when the identity
  * is retrived form the iframe. the visitor id is the first arg
  */
  getIdentity(callback: IdentityType => void | null) {
    if (this.crossSiteDisabled) {
      if (!this.identifiedVisitorData) {
        // As far as I can tell, this is what gets returned by the iframe if it
        // hasn't been initialized some other way.
        this.identifiedVisitorData = { visitorId: null, sessionId: null };
      }
      if (callback) {
        callback(this.identifiedVisitorData);
      }
      return;
    }
    if (callback) {
      this.identifyCallback = callback;
    }

    if (!this.identifiedVisitorData) {
      this.openIdentityIframe();
    } else {
      this.identifyCallback(this.identifiedVisitorData);
    }
  }

  /**
  * @param {string} visitorId - the visitorId to set in the iframe's localStorage
  * @param {string} sessionId - the sessionId to set in the iframe's sessionStorage
  */
  setIdentity(visitorId: string, sessionId: string) {
    if (this.crossSiteDisabled) {
      this.identifiedVisitorData = { visitorId, sessionId };
      return;
    }
    if (!this.identifiedVisitorData) {
      this.openIdentityIframe(visitorId, sessionId);
    } else {
      this.sendVisitorId(this.iframeElement, visitorId);
      this.sendSessionId(this.iframeElement, sessionId);
    }
  }

  /**
  * @param {string | null} visitorId - if there's a visitor id, we first send it
  * @param {string | null} sessionId - if there's a session id, we first send it
  * to the iframe before we request an identification
  */
  openIdentityIframe(visitorId: string | null = null, sessionId: string | null = null) {
    const resource = document.createElement('iframe');
    resource.style.height = '1px';
    resource.style.width = '1px';
    resource.style.opacity = '0';
    resource.style.position = 'fixed';
    window.document.body.append(resource);
    resource.onload = () => {
      if (visitorId) {
        this.sendVisitorId(resource, visitorId);
      }
      if (sessionId) {
        this.sendSessionId(resource, sessionId);
      }
      if (!visitorId && !sessionId) {
        this.requestIdentification(resource);
      }
    };
    resource.src = this.src;
    this.iframeElement = resource;
  }

  /**
  * @param {HTMLElement} iframe - iframe html element
  * @param {string} visitorId - visitor id to send
  */
  sendVisitorId(iframe: any, visitorId: string) {
    if (this.iframeReady) {
      iframe.contentWindow.postMessage({
        source: 'gamalon-identity',
        cmd: IdentityTrackingCommands.SEND_VISITOR_ID_TO_IFRAME,
        data: { visitorId, botId: this.botId },
      }, '*');
    } else {
      // Wait some time and then try again
      setTimeout(() => this.sendVisitorId(iframe, visitorId), 100);
    }
  }

  /**
  * @param {HTMLElement} iframe - iframe html element
  * @param {string} sessionId - session id to send
  */
  sendSessionId(iframe: any, sessionId: string) {
    if (this.iframeReady) {
      iframe.contentWindow.postMessage({
        source: 'gamalon-identity',
        cmd: IdentityTrackingCommands.SEND_SESSION_ID_TO_IFRAME,
        data: { sessionId, botId: this.botId, domain: this.domain },
      }, '*');
    } else {
      // Wait some time and then try again.
      setTimeout(() => this.sendSessionId(iframe, sessionId), 100);
    }
  }

  /**
  * @param {HTMLElement} iframe - iframe html element
  */
  requestIdentification(iframe: any) {
    if (this.iframeReady) {
      iframe.contentWindow.postMessage({
        source: 'gamalon-identity',
        cmd: IdentityTrackingCommands.IDENTIFY,
        data: { botId: this.botId },
      }, '*');
    } else {
      setTimeout(() => {
        this.requestIdentification(iframe);
      }, 100);
    }
  }

  /**
   * Clears localStorage and sessionStorage in the iframe.
   * @param {function} callback Function with no arguments, called when the
   * storage is cleared.
   */
  clearStorage(callback: () => void) {
    if (this.crossSiteDisabled) {
      callback();
      return;
    }
    this.clearStorageCallback = callback;
    if (this.iframeReady && this.iframeElement) {
      this.iframeElement.contentWindow.postMessage({
        source: 'gamalon-identity',
        cmd: IdentityTrackingCommands.CLEAR_STORAGE,
        data: { botId: this.botId },
      }, '*');
    } else {
      // Wait some time and then try again
      setTimeout(() => this.clearStorage(callback), 100);
    }
  }
}

let identityTracker;

const initIdentityTracker = (
  organizationId: string,
  dekuDomain: string,
  botId: number,
  force: boolean = false,
) => {
  if (!identityTracker || force) {
    identityTracker = new IdentityTracker(
      organizationId,
      dekuDomain,
      botId,
    );
  }
};

export const setIdentity = (
  organizationId: string,
  dekuDomain: string,
  botId: number,
  visitorId: string,
  sessionId: string,
) => {
  initIdentityTracker(organizationId, dekuDomain, botId);
  identityTracker.setIdentity(visitorId, sessionId);
};

export const getIdentity = (
  organizationId: string,
  dekuDomain: string,
  botId: number,
): Promise<?IdentityType> => {
  initIdentityTracker(organizationId, dekuDomain, botId);
  return new Promise((resolve) => {
    identityTracker.getIdentity(resolve);
  });
};

export const getOrCreateIdentity = (
  organizationId: string,
  dekuDomain: string,
  botId: number,
): Promise<IdentityType> => {
  initIdentityTracker(organizationId, dekuDomain, botId);

  return new Promise((resolve) => {
    identityTracker.getIdentity(data => {
      const receivedVisitorId = data.visitorId;
      const receivedSessionId = data.sessionId;

      if (!receivedVisitorId || !receivedSessionId) {
        const createdVisitorId = receivedVisitorId || uuidv4();
        const createdSessionId = receivedSessionId || uuidv4();
        identityTracker.setIdentity(createdVisitorId, createdSessionId);
        return resolve({ visitorId: createdVisitorId, sessionId: createdSessionId });
      }

      return resolve({ visitorId: receivedVisitorId, sessionId: receivedSessionId });
    });
  });
};

/**
 * Removes all localStorage and sessionStorage keys used by the bot, so it can
 * start over. This has to be run both in the main window and in the tracking
 * iframe. The main window can do this by calling clearIdentity below.
 * @param {number} botId The bot's ID, which is used as part of the keys.
 */
export function clearStorage(botId: number) {
  // Currently just removing keys that match a couple patterns. I don't know if
  // this is sufficient or if it's necessary. There could theoretically be keys
  // that match these patterns but are not used by us, but set by the
  // customer's page; but it probably doesn't matter since this is intended for
  // our test pages, not live customer pages.
  for (const storage of [window.localStorage, window.sessionStorage]) {
    const gamKeys = [];
    for (let index = 0; index < storage.length; index++) {
      const key = storage.key(index);
      if (key.startsWith(`${botId}-`) || key.startsWith(`gam-${botId}-`)) {
        gamKeys.push(key);
      }
    }
    for (const key of gamKeys) {
      storage.removeItem(key);
    }
  }
}

/**
 * Clears all localStorage and sessionStorage keys used by the chat bar, both
 * in the main window and in the tracking iframe, so that it can start over as
 * a new visitor with a new conversation. Call from the main window.
 *
 * TODO: This doesn't seem to work with cross-domain tracking enabled.
 *
 * @param {string} organizationId The organization ID
 * @param {string} dekuDomain Deku domain
 * @param {number} botId Bot ID
 * @returns {Promise} A Promise that resolves (to undefined) when done.
 */
export const clearIdentity = async (
  organizationId: string,
  dekuDomain: string,
  botId: number,
): Promise<void> => {
  clearStorage(botId);
  await new Promise(resolve => {
    identityTracker.clearStorage(resolve);
  });
  initIdentityTracker(organizationId, dekuDomain, botId, true);
  identityTracker.disableCrossDomainTracking();
};

export const disableCrossDomainTracking = (
  organizationId: string,
  dekuDomain: string,
  botId: number,
) => {
  initIdentityTracker(organizationId, dekuDomain, botId);
  identityTracker.disableCrossDomainTracking();
};
