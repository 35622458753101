/* eslint-disable class-methods-use-this */
import posthog from 'posthog-js';
import * as rudderanalytics from 'rudder-sdk-js';

posthog.init(
  'phc_bTfZtInllCRazZAS5ztSU0kZc4WVsR6RXwom0iSe5wo',
  {
    api_host: 'https://app.posthog.com',
    // Disable automatic recording of pageviews and other user events, since we
    // track those through RudderStack
    autocapture: false,
    capture_pageview: false,
    // But don't disable session recording; we're using that.
  },
);

const API_KEY = '249ImOt7q5gwZlQP7KsO9N6KbNj';
const DATA_PLANE = 'https://gamalonanyfop.dataplane.rudderstack.com';

rudderanalytics.load(API_KEY, DATA_PLANE);

export const AnalyticsEvents = {
  AUTOCOMPLETE_OFFERED: 'bot_offered_autocomplete',
  AUTOCOMPLETE_CLICKED: 'visitor_clicked_autocomplete',
  VISITOR_CLICKED_BUTTON: 'visitor_clicked_button',
  VISITOR_RECEIVED_MARKUP: 'visitor_received_markup',
  CLICKED_WELCOME_MESSAGE: 'visitor_clicked_welcome_message',
  OPENED_BOT: 'visitor_opened_bot',
  BOT_LOADED: 'bot_loaded',
};

/** Analytics class
*/
class Analytics {
  /**
  * @param {string} environment - name of enviornment
  * @param {string} botId - unique bot identifier
  * @param {string} organization - org name
  */
  constructor(environment, botId, organization) {
    this.env = environment;
    this.botId = botId;
    this.organization = organization;
    this.type = 'visitor';
  }

  /**
  * @param {string} visitorId - visitor id
  */
  identify(visitorId) {
    const traits = {
      type: this.type,
      environment: this.env,
      organization: this.organization,
      organization_id: this.organization, // for compatibility with our other apps
    };
    rudderanalytics.identify(visitorId, traits);
    posthog.identify(visitorId, traits);
  }

  /**
  * Get the value of a feature flag.
  * @param {string} featureFlag - feature flag
  * @return {boolean | string} - feature flag value
  */
  getFeatureFlag(featureFlag: string) {
    return posthog.featureFlags.getFeatureFlag(featureFlag);
  }

  /**
  * Waits until a PostHog feature flag has a value other than undefined, and
  * resolves to that value. If it's still undefined after several seconds, it
  * resolves to undefined.
  *
  * This involves repeatedly reloading the feature flag values. It starts with a
  * 100ms delay and doubles the delay each time. The reason for this is that
  * PostHog experiment feature flags can depend on user attributes set in the
  * $identify event, but there's no way of knowing when that has taken effect.
  * The value is undefined if the user is not a member of the experiment
  * audience, but there's no way of knowing whether that's because the user
  * should not be in the audience, or if it's because their attributes are not
  * known to PostHog yet.
  *
  * Our immediate purpose for this is experiments where the bot UI is shown only
  * if the visitor is in the "test" group of the experiment; it is not shown if
  * the visitor is in the "control", nor is it shown if the visitor is not a
  * member of the experiment audience. Usually, if they're in the experiment,
  * this should resolve to "test" or "control" fairly quickly. If they're not in
  * the experiment, it will take several seconds to resolve, but it doesn't
  * matter because nothing will happen then anyway.
  * @param {string} flagName Name of feature flag to wait for
  * @returns {Promise<any>} Resolves to the value of the flag, or undefined if
  * it times out
  */
  async waitForFeatureFlag(flagName: string): Promise<any> {
    console.log('ff:', flagName);
    return new Promise((resolve) => {
      let timeout = false;
      let delay = 100;
      let timer;
      const timerHandler = () => {
        posthog.reloadFeatureFlags();
        delay *= 2;
        if (delay > 3200) {
          timeout = true;
        } else {
          timer = setTimeout(timerHandler, delay);
        }
      };
      timer = setTimeout(timerHandler, delay);
      posthog.onFeatureFlags(() => {
        // This callback is called multiple times; once by onFeatureFlags here
        // and once by each reloadFeatureFlags in the timer handler above.
        const group = posthog.getFeatureFlag(flagName);
        console.log(group);
        if (group !== undefined) {
          console.log('ff result:', group);
          clearTimeout(timer);
          resolve(group);
        }
        if (timeout) {
          console.log('ff timed out');
          clearTimeout(timer);
          resolve(undefined);
        }
      });
    });
  }

  /**
   * Tracks event directly to PostHog for higher reliability than client ->
   * Rudder -> PH (or client -> Deku -> Rudder -> PH). The event name gets
   * "_ph" appended to the end to avoid duplicates when the indirect event does
   * get through.
   * @param {string} event Event name
   * @param {?Object} props Additional props
   */
  trackPh(event, props = {}) {
    posthog.capture(
      event + '_ph',
      {
        environment: this.env,
        bot_id: this.botId,
        organization_id: this.organization,
        url: window.location.href,
        ...props,
      },
    );
  }

  /** */
  page() {
    rudderanalytics.page();
    this.trackPh('pageview');
  }

  /**
  * @param {string} event - visitor id
  * @param {string} props - visitor id
  * @param {string} oncePerSession - visitor id
  */
  track(event, props = {}, oncePerSession = false) {
    if (oncePerSession) {
      const storageKey = `${this.botId}-${event}-analytics-event`;
      const happenedThisSession = !!sessionStorage.getItem(storageKey);
      if (happenedThisSession) {
        return;
      }
      sessionStorage.setItem(storageKey, 'true');
    }
    this.trackPh(event, props);

    const featureFlags = Object.fromEntries(
      Object.entries(
        posthog.featureFlags.getFlagVariants(),
      ).map(([k, v]) => [`$feature/${k}`, v]),
    );
    const properties = {
      environment: this.env,
      bot_id: this.botId,
      organization_id: this.organization,
      url: window.location.href,
      ...featureFlags,
      ...props,
    };
    rudderanalytics.track(event, properties);
  }
}

export default Analytics;
