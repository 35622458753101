// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import HeadlessChat from './HeadlessChat';
import { type InitHooksType } from './entities';
import {
  clearIdentity,
  disableCrossDomainTracking,
} from './tracking';
import './polyfills';

// $flow-disable
import './index.scss';

const reactContainer = document.createElement('div');

let savedConfig;

window.GAMALON = window.GAMALON || {};

// This script creates a variable called "GAMALON" on the global (window) scope.
// It is an object that includes the following methods:
// ** init - Creates the chatbot and injects it into the body of the document
// ** initComponent - Like init but doesn't wait for document load event
// ** close - Shut down and remove chat bar
// ** reset - Start over with empty conversation and new visitor ID. Works only
//    if noCrossDomainTracking is set.
// ** noCrossDomainTracking - disables cross-domain identity tracking if set to true.
// ** getHeadless -
//  * @param {string} organizationId - the organization ID of chatbot
window.GAMALON = {
  ...window.GAMALON || {},
  ...{
    init: (config) => {
      window.addEventListener('load', () => {
        window.GAMALON.initComponent(config);
      }, { once: true });
    },

    initComponent: (config) => {
      if (!config.organizationId) {
        console.error('Configuration missing organization ID');
        return;
      }

      const dekuDomain = config.dekuDomain || 'https://app.gamalon.com';

      if (config.noCrossDomainTracking) {
        disableCrossDomainTracking(config.organizationId, dekuDomain, config.botId);
      }

      reactContainer.classList.add('gamalon-root');

      if (config.container) {
        config.container.appendChild(reactContainer);
      } else {
        window.document.body.appendChild(reactContainer);
      }

      savedConfig = config;

      const initHooks: InitHooksType = {
        onMessageSent(message) {
          if (config.onMessageSent) {
            config.onMessageSent(message);
          }
        },
        onMessageReceived(message) {
          if (config.onMessageReceived) {
            config.onMessageReceived(message);
          }
        },
        onError(error) {
          if (config.onError) {
            config.onError(error);
          }
        },
        onLoading() {
          if (config.onLoading) {
            config.onLoading();
          }
        },
        onLoaded() {
          if (config.onLoaded) {
            config.onLoaded();
          }
        },
      };
      initHooks.onLoading();

      ReactDOM.render(
        <App
          initHooks={initHooks}
          organizationId={config.organizationId}
          dekuDomain={dekuDomain}
          projectId={config.projectId}
          botId={config.botId}
          configId={config.configId}
          gdprBannerSelector={config.cookieBanner}
        />,
        reactContainer,
      );
    },

    close() {
      ReactDOM.render(<span />, reactContainer);
    },

    reset: async (forceReset: boolean) => {
      if (!savedConfig) {
        console.log('Can\'t reset before you init');
        return;
      }
      if (!forceReset && !savedConfig.noCrossDomainTracking) {
        console.log('Can\'t reset unless noCrossDomainTracking was set');
        return;
      }
      window.GAMALON.close();
      await clearIdentity(
        savedConfig.organizationId,
        savedConfig.dekuDomain,
        savedConfig.botId,
      );
      window.GAMALON.initComponent(savedConfig);
    },

    reload: async () => {
      await clearIdentity(
        savedConfig.organizationId,
        savedConfig.dekuDomain,
        savedConfig.botId,
      );
    },

    getHeadless(config) {
      if (!config.organizationId) {
        console.error('Configuration missing organization ID');
        return {};
      }

      const headlessChat = new HeadlessChat();
      const dekuDomain = config.dekuDomain || 'https://app.gamalon.com';
      headlessChat.init(dekuDomain, config);
      return headlessChat;
    },
  },
};
