// @flow
import React from 'react';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
// $flow-disable
import { type MessageType } from '../../entities';

// $flow-disable
import './OptionsWidget.scss';

type OptionsWidgetProps = {
  appId: string,
  conversation: Array<MessageType>,
  resetConversation: () => any,
  downloadTranscript: () => any,
  hadConversation: boolean,
};

const OptionsWidget = (props: OptionsWidgetProps) => {
  const clearChat = () => {
    const lastMessage = props.conversation[props.conversation.length - 1];
    if (lastMessage) {
      localStorage.setItem(`${props.appId}-conversationCutoffId`, lastMessage.id.split('-')[1]);
    }
  };
  return (
    <div
      data-testid="options-panel"
      className={`options-widget-container${!props.hadConversation ? ' options-disabled' : ''}`}
    >
      <div
        data-testid="download-chat-button"
        className="options-widget-option"
        onClick={() => {
          if (props.hadConversation) {
            props.downloadTranscript();
          }
        }}
      >
        <DownloadOutlined />
        &nbsp;
        Download Transcript
      </div>
      <div
        data-testid="clear-chat-button"
        className="options-widget-option"
        onClick={() => {
          if (props.hadConversation) {
            clearChat();
            props.resetConversation();
          }
        }}
      >
        <DeleteOutlined />
        &nbsp;
        Clear Chat
      </div>
    </div>
  );
};

export default OptionsWidget;
