import React, { useEffect, useRef, useState } from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

type ModalProps = {
  children: React.Node,
  organization: string,
  env: string,
};

/**
 * Launch darkly provider wrapper
 * @param {ModalProps} props Component props
 * @returns {React.node} Wrapped components
 */
export const LaunchDarklyProvider = (props: ModalProps) => {
  const [, forceUpdate] = useState(0);
  const LDProvider = useRef(null);

  const clientSideIDs = {
    dev: '5da8b5c479cd890896f357e8',
    integration: '5da8b59179cd890896f357e3',
    staging: '5da8b57b4d2d0b08ab1463e6',
    local: '605374e81d10220e27dc775b', // sandbox
    production: '5da3e0f7edeaf008bd6a3cb3',
  };

  useEffect(() => {
    /**
     * Initializes launch darkly provider
     */
    async function initDarkly() {
      LDProvider.current = await asyncWithLDProvider({
        clientSideID: clientSideIDs[props.env],
        user: {
          key: 'visitor-' + props.organization,
          custom: { organization: props.organization },
        },
      });
      forceUpdate(n => !n);
    }
    if (
      clientSideIDs[props.env]
      && props.organization
    ) {
      initDarkly();
    }
  }, [props.organization]);

  if (!LDProvider.current) {
    return null;
  }

  // eslint-disable-next-line react/jsx-pascal-case
  return <LDProvider.current>{props.children}</LDProvider.current>;
};
