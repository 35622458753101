// @flow

import Smooch from 'smooch';
import { type ChatServerType } from './entities';

const SmoochChatServer: ChatServerType = {
  name: 'smooch',

  isInitialized: false,

  init(appId, userId, jwt) {
    return new Promise((resolve, reject) => {
      Smooch.init({
        appId,
        userId,
        jwt,
        embedded: true,
        soundNotificationEnabled: false,
      })
        .then(() => {
          this.isInitialized = true;
          return resolve();
        })
        .catch(err => reject(err));
    });
  },

  destroy() {
    Smooch.destroy();
  },

  getUserId() {
    if (!this.isInitialized) {
      console.warn('Attempting to get Smooch User ID before initalization');
      return '';
    }
    return Smooch.getUser()._id; // eslint-disable-line
  },

  sendMessage(message) {
    Smooch.sendMessage(message);
  },

  getConversation() {
    return this.isInitialized && Smooch.getConversation && Smooch.getConversation();
  },

  onMessageAboutToSend(beforeSend) {
    Smooch.setDelegate({ beforeSend });
  },

  onMessageSent(callback) {
    Smooch.on('message:sent', callback);
  },

  onMessageReceived(callback) {
    Smooch.on('message:received', callback);
  },

  onTypingStart(callback) {
    Smooch.on('typing:start', callback);
  },

  onTypingStop(callback) {
    Smooch.on('typing:stop', callback);
  },

  cleanup() {
    Smooch.destroy();
  },
};

export default SmoochChatServer;
