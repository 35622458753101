// @flow
import React from 'react';

// $flow-disable
import './SpinningLine.scss';

type SpinningLineProps = {
  color: string,
};

type SpinningLineState = {
  mounted: boolean,
};

/**
* Spinning line component used for showing a loading state.
*/
class SpinningLine extends React.Component<SpinningLineProps, SpinningLineState> {
  /**
  * Constructor
  * @param {TypeheadProps} props props
  */
  constructor() {
    super();
    this.state = {
      mounted: false,
    };
  }

  /**
  * React life cycle method.
  * used for transitioning a class on.
  */
  componentDidMount() {
    this.setState({ mounted: true });
  }

  /**
  * React life cycle method.
  * used for transitioning a class off.
  */
  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  /**
   * Component's main render method - renders the spinning line
   * @returns {React.Node} Rendered content.
   */
  render() {
    const mountedClass = this.state.mounted ? 'g-loading-line--mounted' : '';
    const lineColor = this.props.color ? this.props.color : 'rgb(255, 14, 84)';
    return (
      <div className={`g-loading-line ${mountedClass}`}>
        <div style={{ borderColor: `${lineColor} transparent transparent transparent` }} />
        <div style={{ borderColor: `${lineColor} transparent transparent transparent` }} />
        <div style={{ borderColor: `${lineColor} transparent transparent transparent` }} />
        <div style={{ borderColor: `${lineColor} transparent transparent transparent` }} />
      </div>
    );
  }
}
export default SpinningLine;
