import React from 'react';

export const ReactComponent = (props: any) => (
  <svg width="41" height="41" viewBox="20 20 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <rect x="20" y="20" width="41" height="41" rx="20.5" fill={props.color} />
    </g>
    <path d="M40.3813 48.6187C40.723 48.9604 41.277 48.9604 41.6187 48.6187L47.1872 43.0503C47.5289 42.7085 47.5289 42.1545 47.1872 41.8128C46.8455 41.4711 46.2915 41.4711 45.9497 41.8128L41 46.7626L36.0503 41.8128C35.7085 41.4711 35.1545 41.4711 34.8128 41.8128C34.4711 42.1545 34.4711 42.7085 34.8128 43.0503L40.3813 48.6187ZM40.125 32L40.125 48L41.875 48L41.875 32L40.125 32Z" fill="white" />
    <defs>
      <filter id="filter0_d" x="0" y="0" width="81" height="81" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feOffset />
        <feGaussianBlur stdDeviation="10" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.31 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);
