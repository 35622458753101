import React from 'react';

export const ReactComponent = (props: any) => (
  <svg width="59" height="59" viewBox="20 20 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <rect x="20" y="20" width="59" height="59" rx="29.5" fill={props.color} />
    </g>
    <path d="M61.3427 49.1828C61.3624 47.6664 61.077 46.1613 60.5032 44.7547C59.9294 43.3481 59.0785 42.0681 58 40.9889C56.9215 39.9097 55.6368 39.0528 54.2204 38.468C52.804 37.8832 51.2841 37.582 49.749 37.582C48.2138 37.582 46.694 37.8832 45.2776 38.468C43.8612 39.0528 42.5764 39.9097 41.4979 40.9889C40.4194 42.0681 39.5685 43.3481 38.9947 44.7547C38.4209 46.1613 38.1356 47.6664 38.1553 49.1828C38.1553 49.2243 38.1553 49.2648 38.1553 49.3063C38.1553 49.3251 38.1553 49.344 38.1553 49.3628C38.1554 53.3994 39.7489 57.2769 42.5971 60.1713C45.4454 63.0658 49.3238 64.749 53.4084 64.8633V63.7501C52.5487 63.6465 51.7383 63.2968 51.0771 62.7441C50.4159 62.1915 49.9327 61.4599 49.687 60.6394H49.7547C52.8291 60.6377 55.777 59.4298 57.95 57.2814C60.1231 55.1329 61.3434 52.2199 61.3427 49.1828Z" fill="white" />
    <defs>
      <filter id="filter0_d" x="0" y="0" width="99" height="99" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feOffset />
        <feGaussianBlur stdDeviation="10" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.31 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);
