// @flow
import { type ChatSettings } from './entities';

type WelcomeMessageRuleData = {
  newOrReturning: 'New' | 'Returning',
  url: string,
}

const allRulesMatch = (rules, utmParameters, visitorData) => (
  rules.every(({ type, value }) => (
    (type === 'Current URL includes...' && (value === 'DEFAULT' || visitorData.url.toLowerCase().includes(value.toLowerCase())))
    || (type === 'New or Returning Visitor' && value.toLowerCase() === visitorData.newOrReturning.toLowerCase())
    || (type.startsWith('UTM_') && value.toLowerCase() === utmParameters[type.toLowerCase()])
  ))
);

/**
* This functions compiles the welcomesMessages from the welcomesMessages2 object. It replaces
* the welcomeMessage object that exist in the chat settings, if welcomemessage2 exists.
*
* @param {ChatSettings} settings - chat settings, where both welcomeMessages and
* welcomeMessages2 exist
* @param {Object} utmParameters - object containing utm parameter values
* @param {WelcomeMessageRuleData} visitorData - contains info on new vs returning and current page
*/
export const compileWelcomeMessages = (
  settings: ChatSettings,
  utmParameters: any,
  visitorData: WelcomeMessageRuleData,
) => {
  const { welcomeMessages, welcomeMessages2 = [] } = settings;
  const activeWelcomeMessages = welcomeMessages2.filter(({ disabled }) => !disabled);

  if (activeWelcomeMessages.length) {
    const newWelcomeMessages = { intro: [], return: [], delay: welcomeMessages.delay };

    for (let i = 0; i < activeWelcomeMessages.length; i += 1) {
      const { rules, message } = activeWelcomeMessages[i];

      if (allRulesMatch(rules, utmParameters, visitorData)) {
        const urlObj = rules.find(r => r.type === 'Current URL includes...');
        const newOrReturningRule = rules.find(r => r.type === 'New or Returning Visitor');

        message.forEach(msg => {
          const msgObj = {
            text: msg,
            url: urlObj?.value || 'DEFAULT',
          };

          if (newOrReturningRule === undefined) {
            newWelcomeMessages.intro.push(msgObj);
            newWelcomeMessages.return.push(msgObj);
          } else if (newOrReturningRule.value === 'New') {
            newWelcomeMessages.intro.push(msgObj);
          } else {
            newWelcomeMessages.return.push(msgObj);
          }
        });
        break;
      }
    }

    settings.welcomeMessages = newWelcomeMessages;
  }
};
