/**
 * Parses UTM parameters from the query string and stores them in session storage and returns them.
 * @param {number} botId - bot id
 * @returns {{
 *  utmSource: string,
 *  utmTerm: string,
 *  utmContent: string,
 *  utmMedium: string,
 *  utmCampaign: string,
 * }} The UTM parameters from the query string or session storage.
 */
function getUtmParameters(botId: number) {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const utmCampaign = urlParams.get('utm_campaign');
  const utmMedium = urlParams.get('utm_medium');
  const utmSource = urlParams.get('utm_source');
  const utmContent = urlParams.get('utm_content');
  const utmTerm = urlParams.get('utm_term');

  const { sessionStorage } = window;

  if (utmCampaign || utmMedium || utmSource || utmContent || utmTerm) {
    sessionStorage.setItem(`${botId}-utmCampaign`, utmCampaign);
    sessionStorage.setItem(`${botId}-utmMedium`, utmMedium);
    sessionStorage.setItem(`${botId}-utmSource`, utmSource);
    sessionStorage.setItem(`${botId}-utmContent`, utmContent);
    sessionStorage.setItem(`${botId}-utmTerm`, utmTerm);
  }

  /**
   * TODO: here we should make a call to deku so these can be written
   *  to the database for reporting.
   */

  return {
    utm_campaign: sessionStorage.getItem(`${botId}-utmCampaign`),
    utm_medium: sessionStorage.getItem(`${botId}-utmMedium`),
    utm_source: sessionStorage.getItem(`${botId}-utmSource`),
    utm_content: sessionStorage.getItem(`${botId}-utmContent`),
    utm_term: sessionStorage.getItem(`${botId}-utmTerm`),
  };
}

export default getUtmParameters;
