import axios from 'axios';
import queryString from 'query-string';

export const getUserJWT = (domain, userId, organizationId) => new Promise((resolve, reject) => {
  const url = `${domain}/deku/user_jwt`;
  const queryParams = queryString.stringify({ userId, organizationId });
  const endpoint = `${url}?${queryParams}`;
  axios.get(endpoint)
    .then(({ data }) => resolve(data))
    .catch(error => reject(error));
});

export const createVisitor = async (domain, id) => {
  const endpoint = `${domain}/deku/visitors`;
  return axios.post(endpoint, { id });
};

const createWebEvent = (
  domain,
  botId,
  visitorId,
  smoochAppUserId,
  sessionId,
  eventType,
  iframeInterface = { inIframe: false },
  token = null,
  _data = {},
) => {
  let url = window.location.href;
  let { referrer } = document;

  if (iframeInterface.inIFrame) {
    url = iframeInterface.href || url;
    referrer = iframeInterface.referrer || referrer;
  }

  const params = {
    ..._data,
    botId,
    eventType,
    visitorId,
    smoochAppUserId,
    url,
    referrer,
    sessionId,
  };

  const endpoint = `${domain}/deku/events`;
  const axiosConfig = { params };

  if (token) {
    axiosConfig.headers = { Authorization: `Bearer ${token}` };
  }

  return axios.post(endpoint, null, axiosConfig).catch(e => {
    console.error(e);
    throw e;
  });
};

// These Web Events need to stay coordinated with the WebEventType Enum in deku
const WebEventType = {
  PAGE_OPENED: 'PAGE_OPENED',
  PAGE_CLOSED: 'PAGE_CLOSED',
  VISITOR_MESSAGE_SENT: 'VISITOR_MESSAGE_SENT',
  BOT_MESSAGE_RECEIVED: 'BOT_MESSAGE_RECEIVED',
  DEMO_LINK_OFFERED: 'DEMO_LINK_OFFERED',
  DEMO_LINK_CLICKED: 'DEMO_LINK_CLICKED',
  DEMO_LINK_SCHEDULED: 'DEMO_LINK_SCHEDULED',
  VISITOR_IDLE: 'VISITOR_IDLE',
  CONVERSION: 'CONVERSION',
  WEB_SESSION_START: 'WEB_SESSION_START',
  WEB_SESSION_END: 'WEB_SESSION_END',
};

export const createPageOpenedWebEvent = (
  domain,
  botId,
  visitorId,
  smoochAppUserId,
  sessionId,
  iframeInterface = { inIframe: false },
) => (
  createWebEvent(
    domain,
    botId,
    visitorId,
    smoochAppUserId,
    sessionId,
    WebEventType.PAGE_OPENED,
    iframeInterface,
  )
);

export const createVisitorIdleWebEvent = (
  domain,
  botId,
  visitorId,
  smoochAppUserId,
  sessionId,
  iframeInterface = { inIframe: false },
) => (
  createWebEvent(
    domain,
    botId,
    visitorId,
    smoochAppUserId,
    sessionId,
    WebEventType.VISITOR_IDLE,
    iframeInterface,
  )
);

export const createConversionWebEvent = (
  domain,
  botId,
  visitorId,
  smoochAppUserId,
  sessionId,
  conversionType,
) => (
  createWebEvent(
    domain,
    botId,
    visitorId,
    smoochAppUserId,
    sessionId,
    WebEventType.CONVERSION,
    { inIframe: false },
    null,
    { conversionType },
  )
);

export const createPageClosedWebEvent = (
  domain,
  botId,
  visitorId,
  smoochAppUserId,
  sessionId,
  iframeInterface = { inIframe: false },
) => (
  createWebEvent(
    domain,
    botId,
    visitorId,
    smoochAppUserId,
    sessionId,
    WebEventType.PAGE_CLOSED,
    iframeInterface,
  )
);

export const createMessageSentWebEvent = (
  domain,
  botId,
  visitorId,
  smoochAppUserId,
  sessionId,
  iframeInterface = { inIframe: false },
) => (
  createWebEvent(
    domain,
    botId,
    visitorId,
    smoochAppUserId,
    sessionId,
    WebEventType.VISITOR_MESSAGE_SENT,
    iframeInterface,
  )
);

export const createMessageReceivedWebEvent = (
  domain,
  botId,
  visitorId,
  smoochAppUserId,
  sessionId,
  iframeInterface = { inIframe: false },
) => (
  createWebEvent(
    domain,
    botId,
    visitorId,
    smoochAppUserId,
    sessionId,
    WebEventType.BOT_MESSAGE_RECEIVED,
    iframeInterface,
  )
);

export const createDemoLinkOfferedWebEvent = (
  domain,
  botId,
  visitorId,
  smoochAppUserId,
  sessionId,
  iframeInterface = { inIframe: false },
) => (
  createWebEvent(
    domain,
    botId,
    visitorId,
    smoochAppUserId,
    sessionId,
    WebEventType.DEMO_LINK_OFFERED,
    iframeInterface,
  )
);

export const createDemoLinkClickedWebEvent = (
  domain,
  botId,
  visitorId,
  smoochAppUserId,
  sessionId,
  iframeInterface = { inIframe: false },
) => (
  createWebEvent(
    domain,
    botId,
    visitorId,
    smoochAppUserId,
    sessionId,
    WebEventType.DEMO_LINK_CLICKED,
    iframeInterface,
  )
);

export const createDemoLinkScheduledWebEvent = (
  domain,
  botId,
  visitorId,
  smoochAppUserId,
  sessionId,
  iframeInterface = { inIframe: false },
) => (
  createWebEvent(
    domain,
    botId,
    visitorId,
    smoochAppUserId,
    sessionId,
    WebEventType.DEMO_LINK_SCHEDULED,
    iframeInterface,
  )
);

export const createWebSessionStartWebEvent = (
  domain,
  botId,
  visitorId,
  smoochAppUserId,
  sessionId,
  iframeInterface = { inIframe: false },
) => (
  createWebEvent(
    domain,
    botId,
    visitorId,
    smoochAppUserId,
    sessionId,
    WebEventType.WEB_SESSION_START,
    iframeInterface,
  )
);

export const createWebSessionEndWebEvent = (
  domain,
  botId,
  visitorId,
  smoochAppUserId,
  sessionId,
  iframeInterface = { inIframe: false },
) => (
  createWebEvent(
    domain,
    botId,
    visitorId,
    smoochAppUserId,
    sessionId,
    WebEventType.WEB_SESSION_END,
    iframeInterface,
  )
);

export const pingProactive = (domain, token, configId) => {
  const endpoint = `${domain}/deku/messages/idle`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return axios.post(endpoint, { configId }, axiosConfig);
};

export const downloadTranscript = (domain, token, appId, smoochAppUserId) => {
  const endpoint = `${domain}/deku/apps/${appId}/transcript/${smoochAppUserId}/download`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return axios.get(endpoint, axiosConfig);
};

export const createFeedback = (domain, token, messageId, feedbackType) => {
  const endpoint = `${domain}/deku/messages/${messageId}/feedback?type=${feedbackType}`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return axios.post(endpoint, {}, axiosConfig);
};

export const getSettings = async (projectId, organizationId, botId) => {
  const url = 'https://firestore.googleapis.com/v1beta1';
  const databasePath = `/projects/${projectId}/databases/(default)`;
  const docPath = `/documents/organizations/${organizationId}/cw/${botId}/misc/chatSettings`;
  const endpoint = `${url}${databasePath}${docPath}`;
  return axios.get(endpoint);
};

export const getFeatureFlags = (domain, token, appId) => {
  const endpoint = `${domain}/deku/apps/${appId}/feature_flags`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return axios.get(endpoint, axiosConfig);
};

export const getCatalogSuggestionMatches = async (query, projectId, organization, botId) => {
  const endpoint = `https://us-central1-${projectId}.cloudfunctions.net/catalogQuestionSearch`;
  try {
    const responseCatalog = await axios.post(endpoint, {
      data: {
        query,
        organization,
        botId,
      },
    });
    return responseCatalog?.data;
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const getTypeaheadSuggestions = async (
  domain,
  token,
  appId,
  configId: ?number,
) => {
  let endpoint = `${domain}/deku/apps/${appId}/typeahead_suggestions`;
  if (configId) {
    endpoint += `&configId=${configId}`;
  }
  const axiosConfig = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return axios.get(endpoint, axiosConfig);
};

export const getVisitor = (domain, token, appId, visitorId) => {
  const endpoint = `${domain}/deku/apps/${appId}/visitor/${visitorId}`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return axios.get(endpoint, axiosConfig);
};

export const getZendeskTicketFields = (domain, token, appId) => {
  const endpoint = `${domain}/deku/apps/${appId}/zendesk/ticket_fields`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return axios.get(endpoint, axiosConfig);
};

export const createZendeskTicket = (domain, token, appId, data) => {
  const endpoint = `${domain}/deku/apps/${appId}/zendesk/ticket`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return axios.post(endpoint, data, axiosConfig);
};
