// @flow
import React, { useState } from 'react';
import { EllipsisOutlined, DownloadOutlined } from '@ant-design/icons';

// $flow-disable
import './Options.scss';

type OptionsProps = {
  settings: Object,
  downloadTranscript: () => any,
  hadConversation: boolean,
};

const componentRef = React.createRef();

const Options = (props: OptionsProps) => {
  const [open, setOpen] = useState(false);

  let positionClass = '';
  if (props.settings.design.position === 'Right') {
    positionClass = 'options-right-sided';
  }

  if (!props.hadConversation) {
    return null;
  }

  let menu = null;

  if (open) {
    menu = (
      <>
        <div
          className={`options-menu-container ${positionClass}`}
          ref={componentRef}
          data-testid="download-chat-button"
          style={{ fontSize: '10px', fontWeight: 400 }}
          onClick={() => {
            if (props.hadConversation) {
              props.downloadTranscript();
            }
          }}
        >
          <div
            className="options-menu-icon"
            style={{ fontSize: '23px' }}
          >
            <DownloadOutlined />
          </div>
          <div className="option-menu-text">
            Download Transcript
          </div>
        </div>
        <div className="options-menu-container-triangle" />
      </>
    );
  }

  return (
    <div
      aria-label="options-button"
      ref={componentRef}
      onClick={() => setOpen(!open)}
      className="options-icon-container"
    >
      { menu }
      <EllipsisOutlined
        style={{ fontSize: '20px', color: '#ACACAE' }}
      />
    </div>
  );
};

export default Options;
