// @flow
import React from 'react';
// import { ReactComponent as Quote } from '../../assets/images/quote.svg';
// $flow-disable
import './QuotePulse.scss';

type QuotePulseProps = {
  isLoading: boolean,
  layout: string,
  color: string,
};

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);
    return `rgb(${r}, ${g}, ${b})`;
  }

  return null;
};

/**
* Spinning line component used for showing a loading state.
* @param {any} props - component properites: isLoading, layout, colors
* @returns {React.node} quote symbol with loading pulse animation,
* both with customized colors if selected, otherwise defaults
*/
function QuotePulse(props: QuotePulseProps) {
  const { isLoading, layout } = props;
  let { color } = props;

  const loadingClass = isLoading ? 'loading' : '';
  const layoutClass = layout;

  if (color && color.startsWith('#')) {
    color = hexToRgb(color);
  }

  const quoteRGB = color || 'rgb(255, 14, 84)';
  const quoteColorMatchSet = /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/;
  const quoteColorValues = quoteColorMatchSet.exec(quoteRGB) || [0, 0, 0];
  const quoteFill = `rgba(${quoteColorValues[1]}, ${quoteColorValues[2]}, ${quoteColorValues[3]}, 1)`;
  const quotePulseLoaderFill = `rgba(${quoteColorValues[1]}, ${quoteColorValues[2]}, ${quoteColorValues[3]}, 0.17)`;

  return (
    <div className="g-quote-loader-wrap">
      <div className={`g-quote-loader-wrap__quote g-quote-loader-wrap__quote--${layoutClass}`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="104.478" height="123.755" viewBox="0 0 104.478 123.755">
          <path
            id="Path_780"
            data-name="Path 780"
            d="M1253.093,144.134a52.239,52.239,0,1,0-104.478,0c0,.188.005.376.007.564,0,.086-.007.171-.007.257a70.725,70.725,0,0,0,68.722,70.7v-5.093a20.012,20.012,0,0,1-16.787-14.188c.1,0,.2,0,.3,0A52.239,52.239,0,0,0,1253.093,144.134Z"
            transform="translate(-1148.615 -91.895)"
            fill={quoteFill}
          />
        </svg>
      </div>
      <div className="g-quote-loader-wrap__pink-dot">
        <svg x="0px" y="0px" width="100%" height="100%" viewBox="0 0 106 106" xmlns="http://www.w3.org/2000/svg">
          <circle
            className={loadingClass}
            id="outerCore"
            cx="53"
            cy="53"
            r="48"
            fill={quotePulseLoaderFill}
          />
          <circle
            className={loadingClass}
            id="outerCoreLoader"
            cx="53"
            cy="53"
            r="48"
            fill={quoteFill}
          />
        </svg>
      </div>
    </div>
  );
}

export default QuotePulse;
