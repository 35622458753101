// @flow
import React from 'react';
import { AudioOutlined, AudioFilled } from '@ant-design/icons';

import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import SpinningLine from './loaders/SpinningLine';
import TypeHeadBase from './TypeHeadBase';
import TypeHeadClassic from './ConversationBaseChatClassic/TypeHeadClassic';
import TypeHeadBubble from './ConversationBaseChatBubble/TypeHeadBubble';

import { type ChatSettings } from '../entities';
import { AnalyticsEvents } from '../analytics';
import { highlightMatchedSubString } from '../utils/typeHead';
import { catchAll } from '../utils/catchAll';

type TypeheadProps = {|
  type: string,
  widgetOpen: boolean,
  sendMessage: string => any,
  settings: ChatSettings,
  handleConversationFocusState: boolean => any,
  chatThreadRef: Object,
  optionsRef: Object,
  newMessageCount: number,
  resetNewMessageCount: () => any,
  waitingForSystemResponse: boolean,
  placeholder: string,
  resetProactivePing: (() => boolean) => any,
  isLastMessageAQuestion: () => boolean,
  getTypeHeadCatalogSuggestions: (string) => void,
  typeaheadSuggestions: Array<string>,
  typeaheadCatalogSuggestions: Array<string>,
  downloadTranscript: () => any,
  onDefaultTriggerClick: () => void,
  hadConversation: boolean,
  flags: Object, // from withLDConsumer (LaunchDarkly).
  typeheadRef: any,
  // eslint-disable-next-line react/no-unused-prop-types
  onError: string => any, // callback for error reporting
  analytics: Object,
|};

type TypeHeadState = {
  message: string,
  focused: boolean,
  showAutoCompletePanel: boolean,
  recording: boolean,
  selectedAutoCompleteIndex: number | null,
  selectedAutoCompleteType: 'arrow' | 'hover' | 'initial',
  recorder: any,
  title: string,
};

/**
 * Formats a message for auto complete. In this context, formatting means
 * transforming the message so that product specific words are triggered even
 * though the spelling is different. For example: 'idea flow' should match to
 * 'IdeaFlow'
 *
 * This method is specific to the Gamalon bot
 * @param {string} message props
 * @returns {string} - formatted message
 */
const transformMessageForAutoComplete = (message) => {
  const products = ['mail', 'flow', 'analytics'];
  const isProduct = word => products.some(p => p.startsWith(word));
  const nMessage = [];
  const words = message.split(' ');
  words.forEach((word, i) => {
    const prevWord = nMessage[i - 1];
    if (prevWord && prevWord.toLowerCase() === 'idea' && isProduct(word)) {
      nMessage[i - 1] = `${prevWord}${word}`;
    } else {
      nMessage.push(word);
    }
  });
  return nMessage.join(' ');
};

/**
 * Conversation's input bar, used to add user messages to conversation.
 */
class TypeHead extends React.Component<TypeheadProps, TypeHeadState> {
  nodeRef: any;
  trackedAutoCompleteFlag: boolean;

  /**
   * Constructor
   * @param {TypeheadProps} props props
   */
  constructor(props: TypeheadProps) {
    super(props);
    this.state = {
      message: '',
      focused: false,
      recording: false,
      showAutoCompletePanel: false,
      selectedAutoCompleteIndex: null,
      selectedAutoCompleteType: 'initial',
      recorder: null,
      title: document.title,
    };

    this.nodeRef = React.createRef();

    this.bindDocumentListener();
    this.trackedAutoCompleteFlag = false;
  }

  /**
   * React life cycle method.
   */
  componentWillMount() {
    window.addEventListener('click', this.removeAutoCompleteClickEvent);
    window.addEventListener('keydown', this.handleAutoCompleteKeyNavigation);
  }

  /**
   * React life cycle method.
   */
  componentWillUnmount() {
    window.removeEventListener('click', this.removeAutoCompleteClickEvent);
    window.document.removeEventListener('mousedown', this.onMouseDown);
    window.removeEventListener('keydown', this.handleAutoCompleteKeyNavigation);
  }

  /**
   * Event handler to remove auto complete
    * @param {Object} e - click event
   */
  removeAutoCompleteClickEvent = catchAll(this.props.onError, (e: any) => {
    if (e.target.id !== 'e2e-type-head-input' && e.target.id !== 'auto-complete') {
      this.setState({ showAutoCompletePanel: false });
      this.props.resetProactivePing(() => this.state.showAutoCompletePanel);
    }
  });

  /**
   * Event handler to auto complete navigation
    * @param {Object} e - click event
   */
  handleAutoCompleteKeyNavigation = catchAll(this.props.onError, (e: any) => {
    const { message, showAutoCompletePanel } = this.state;
    const { resetProactivePing } = this.props;
    const autoCompleteMessages = this.getAutoCompleteMessages();
    if (
      autoCompleteMessages.length
      && message
      && showAutoCompletePanel
    ) {
      let { key } = e;
      const { keyIdentifier } = e;
      // fix for old safari
      if (!key) {
        if (keyIdentifier === 'U+001B') {
          key = 'Escape';
        } else if (keyIdentifier === 'Up') {
          key = 'ArrowUp';
        } else if (keyIdentifier === 'Down') {
          key = 'ArrowDown';
        } else {
          key = keyIdentifier;
        }
      }
      switch (key) { // eslint-disable-line default-case
        case 'ArrowUp':
          this.setState(({ selectedAutoCompleteIndex }) => ({
            selectedAutoCompleteType: 'arrow',
            selectedAutoCompleteIndex: (
              selectedAutoCompleteIndex
                ? selectedAutoCompleteIndex - 1
                : autoCompleteMessages.length - 1
            ),
          }));
          break;
        case 'ArrowDown':
          this.setState(({ selectedAutoCompleteIndex }) => ({
            selectedAutoCompleteType: 'arrow',
            selectedAutoCompleteIndex: (
              selectedAutoCompleteIndex === null || selectedAutoCompleteIndex === autoCompleteMessages.length - 1 // eslint-disable-line max-len
                ? 0
                : selectedAutoCompleteIndex + 1
            ),
          }));
          break;
        case 'Escape':
          this.setState({
            showAutoCompletePanel: false,
            selectedAutoCompleteIndex: null,
          });
          break;
        case 'Enter':
          if (this.state.selectedAutoCompleteIndex !== null && this.state.selectedAutoCompleteType !== 'hover') {
            this.props.sendMessage(autoCompleteMessages[this.state.selectedAutoCompleteIndex]);
            this.setState({ message: '', selectedAutoCompleteIndex: null });
            e.stopPropagation();
          }
          break;
      }
      resetProactivePing(() => this.state.showAutoCompletePanel);
    }
  });

  /**
   * @returns {Array<string>} - Gets all of the auto complete possibilities
   */
  getAutoCompleteMessages(): Array<string> {
    const { message } = this.state;
    const { typeaheadSuggestions, typeaheadCatalogSuggestions } = this.props;
    const transformedMessage = transformMessageForAutoComplete(message);

    const dekuSuggestions = typeaheadSuggestions.filter((acMessage: string) => (
      (
        acMessage.toLowerCase().includes(transformedMessage.toLowerCase())
        && acMessage.toLowerCase() !== transformedMessage.toLowerCase()
      )
      || (
        acMessage.toLowerCase().includes(message.toLowerCase())
        && acMessage.toLowerCase() !== message.toLowerCase()
      )
    )).slice(0, 4);
    const catalogSuggestions = typeaheadCatalogSuggestions.slice(
      0,
      Math.max(4, 8 - dekuSuggestions.length),
    );
    return [...new Set([...dekuSuggestions, ...catalogSuggestions])];
  }

  /**
   * Bind off-click handlers via local refs
   * and to exclude other components from off-click region, pass their refs over.
   */
  bindDocumentListener() {
    window.document.addEventListener('mousedown', this.onMouseDown);
  }

  /**
   * @param {event} e - event object
   * Mouse down event
   */
  onMouseDown = catchAll(this.props.onError, (e: any) => {
    const { focused } = this.state;

    const isModal = (node) => {
      if (!node?.classList) {
        return false;
      }
      if ([...node.classList].includes('popup-overlay')) {
        return true;
      }
      return isModal(node.parentNode);
    };

    const isCloseButton = node => (
      [...node.classList].includes('close-button')
    );

    const isRatingOverlay = node => {
      const threadParentNode = this.props.chatThreadRef.current.parentNode;
      return threadParentNode.contains(node);
    };

    if (focused
      && !this.nodeRef.current.contains(e.target)
      && !this.props.chatThreadRef.current.contains(e.target)
      // $flow-disable
      && !this.props.optionsRef?.current?.contains(e.target)
      && !isModal(e.target)
      && !isCloseButton(e.target)
      && !isRatingOverlay(e.target)
    ) {
      this.handleBlur();
    }
  });

  /**
   * Set focused state in this component and in parent conversation
   * @return {void}
   */
  handleFocus = catchAll(this.props.onError, () => {
    this.setState({ focused: true });
    this.props.handleConversationFocusState(true);
    this.props.resetNewMessageCount();

    // Remove "unread messages number" from browser title
    this.updateTitle(0);
  });

  /**
   * Set blurred state in this component and parent conversation
   * @return {void}
   */
  handleBlur = catchAll(this.props.onError, () => {
    this.setState({ focused: false });
    this.props.handleConversationFocusState(false);
  });

  /**
   * change message input value
   * @param {Object} e - inputs event object
   * @return {void}
   */
  handleChange = catchAll(this.props.onError, (e: Object) => {
    this.setState({
      message: e.target.value,
      showAutoCompletePanel: !!e.target.value,
    });
    this.props.getTypeHeadCatalogSuggestions(e.target.value.trim());
    this.props.resetProactivePing(() => this.state.showAutoCompletePanel);
  });

  /**
   * Handle pressing enter key on input
   * @param {Object} e - inputs event object
   * @return {void}
   */
  handleKeyPress = catchAll(this.props.onError, (e: Object) => {
    if (e.keyCode === 13 && this.state.message) {
      this.handleAddingMessageToConversation();
    }
    this.props.resetProactivePing(() => this.state.showAutoCompletePanel);
  });

  /**
   * Set a unique message ID and Add message
   * to conversation, then and clear out input and ID.
   * @return {void}
   */
  handleAddingMessageToConversation = catchAll(this.props.onError, () => {
    this.props.sendMessage(this.state.message);
    this.setState({ message: '' });
  });

  /**
   * Clear input
   * @return {void}
   */
  handleClearInput = catchAll(this.props.onError, () => {
    this.setState({
      message: '',
      focused: true,
    });
    this.props.handleConversationFocusState(true);
  });

  /**
   * Start recording audio and setup the event callbacks
   * @return {void}
   */
  startRecording = () => {
    // eslint-disable-next-line new-cap
    const recorder = new window.webkitSpeechRecognition();
    let recorderTimeout = null;
    recorder.continuous = true;
    recorder.interimResults = true;

    recorder.onstart = () => {
      this.setState({ recording: true, recorder, focused: true });
    };

    recorder.onend = () => {
      clearTimeout(recorderTimeout);
      this.setState({ recording: false, recorder: null });

      if (this.state.message) {
        this.handleFocus();
        this.handleAddingMessageToConversation();
      }
    };

    // This callback is called every several milliseconds, when we get a new
    // snippet of dialouge. Each time it's called, we set a timeout to stop the
    // recordig within the next second. We also clear any previous timeout if it
    // existed. The effect is that the bot will stop recording after one second
    // of silence.
    recorder.onresult = (e: any) => {
      this.setState({ message: e.results[0][0].transcript });
      if (recorderTimeout) {
        clearTimeout(recorderTimeout);
      }
      recorderTimeout = setTimeout(() => this.stopRecording(), 1000);
    };

    recorder.start();
  }

  /**
   * Stop recording audio
   * @return {void}
   */
  stopRecording = catchAll(this.props.onError, () => {
    this.state.recorder.stop();
  });

  /**
   * Render the customer's avatar image with a loading state handler
   * @returns {React.Node} Rendered content.
   */
  renderCustomAvatar = () => {
    const { settings, waitingForSystemResponse } = this.props;
    return (
      <div className="g-typehead__logo-wrap__custom">
        { waitingForSystemResponse ? <SpinningLine color={settings.design.highlightColor} /> : '' }
        <div
          className="g-typehead__logo-wrap__custom__img"
          style={{ backgroundImage: `url(${settings.design.logo})` }}
        />
      </div>
    );
  }

  /**
   * Render an audio mic if the webkitSpeechRecognition API is availible
   * @returns {React.Node} Rendered content.
   */
  renderAudioMic = () => {
    if (!window.GAMALON_DEBUG_MODE_ACTIVE || window.webkitSpeechRecognition === undefined) {
      return null;
    }

    return (
      this.state.recording
        ? (
          <AudioFilled
            style={{ marginRight: 10, cursor: 'pointer' }}
            onClick={this.stopRecording}
          />
        )
        : (
          <AudioOutlined
            style={{ marginRight: 10, cursor: 'pointer' }}
            onClick={this.startRecording}
          />
        )
    );
  }

  /**
   * Renders the auto complete suggestion element
   * @param {string} suggestion Suggestion value
   * @returns {React.Node} Rendered content.
   */
  renderAutoCompleteSuggestion = (suggestion) => {
    const resultRender = [];
    const { message } = this.state;
    const hiMatchData = highlightMatchedSubString(message, suggestion);
    if (hiMatchData.matches) {
      for (let f = 0; f < hiMatchData.matchData.length; f++) {
        const segment = hiMatchData.matchData[f];
        if (typeof segment === 'string') {
          resultRender.push(
            <span key={`seg-${f * 9}`} className="unmatched-text">
              {segment}
            </span>,
          );
        } else {
          resultRender.push(
            <span key={`seg-${f * 9}`} className="matched-text">
              {segment.matchValue}
            </span>,
          );
        }
      }
    } else {
      resultRender.push(
        <span key="unmatched" className="unmatched-text">
          {suggestion}
        </span>,
      );
    }
    return resultRender;
  }

  /**
   * Renders the auto complete modal
   * @returns {React.Node} Rendered content.
   */
  renderAutoComplete = () => {
    const { message, showAutoCompletePanel, selectedAutoCompleteIndex } = this.state;
    const { isLastMessageAQuestion, analytics } = this.props;
    const autoCompleteMessages = this.getAutoCompleteMessages();
    const transformedMessage = transformMessageForAutoComplete(message);
    if (
      autoCompleteMessages.length
      && transformedMessage
      && showAutoCompletePanel
      && !isLastMessageAQuestion()
    ) {
      if (!this.trackedAutoCompleteFlag) {
        analytics.track(AnalyticsEvents.AUTOCOMPLETE_OFFERED);
        this.trackedAutoCompleteFlag = true;
      }

      return (
        <div className="auto-complete-container" id="auto-complete">
          <span className="top-bot" />
          {
            autoCompleteMessages.map((acMessage, i) => {
              let className = 'auto-complete-message';
              if (selectedAutoCompleteIndex === i) {
                className += ' selected-auto-complete-message';
              }
              return (
                <div
                  key={acMessage}
                  className={className}
                  onClick={() => {
                    analytics.track(AnalyticsEvents.AUTOCOMPLETE_CLICKED, { text: acMessage });
                    this.props.sendMessage(acMessage);
                    this.setState({ message: '' });
                  }}
                  onMouseEnter={() => (
                    this.setState({ selectedAutoCompleteType: 'hover', selectedAutoCompleteIndex: i })
                  )}
                  onMouseLeave={() => (
                    this.setState({ selectedAutoCompleteType: 'hover', selectedAutoCompleteIndex: null })
                  )}
                >
                  {this.renderAutoCompleteSuggestion(acMessage)}
                </div>
              );
            })
          }
        </div>
      );
    }

    // Every tme the autocomplete closes, we want to reset the `trackedAutoCompleteFlag`
    // bool. The effect is that a new analytics event will trigger only when the autocompelete
    // opens, and not on every render.
    this.trackedAutoCompleteFlag = false;

    if (selectedAutoCompleteIndex !== null) {
      this.setState({ selectedAutoCompleteIndex: null });
    }

    return null;
  }

  /**
   * Update browser title with number of unread messages.
   * @param {number} newMessageCount Number of unread messages
   * @returns {void}
   */
  updateTitle(newMessageCount:number) {
    const { showUnreadMesageCount } = this.props.flags;
    if (showUnreadMesageCount) {
      let newTitle;
      if (newMessageCount === 0) {
        newTitle = this.state.title;
      } else {
        newTitle = `(${newMessageCount}) ${this.state.title}`;
      }

      document.title = newTitle;
    }
  }

  /**
   * Update typehead and browser title with number of unread messages.
   * @param {number} newMessageCount number of unread messages
   * @returns {JSX.Element} Number on the right side of input bar,
   * indicating unread messages.
   */
  updateUnreadMsgNumber = (newMessageCount: number) => {
    this.updateTitle(newMessageCount);
    return (
      <div className="g-typehead__new-message-indication">
        <span
          data-testid="new-message-icon"
          className="g-typehead__new-message-indication__new-message"
        >
          { newMessageCount }
        </span>
      </div>
    );
  }

  /**
   * Render the chats input element based on the form-factor property.
   * @returns {React.Node} Rendered content.
   */
  renderTypeHead = () => {
    if (this.props.type === 'classic') {
      return (
        <TypeHeadClassic
          nodeRef={this.nodeRef}
          message={this.state.message}
          placeholder={this.props.placeholder}
          typeheadRef={this.props.typeheadRef}
          onFocus={this.handleFocus}
          onChange={this.handleChange}
          onKeyUp={this.handleKeyPress}
          widgetOpen={this.props.widgetOpen}
          newMessageCount={this.props.newMessageCount}
          renderAudioMic={this.renderAudioMic}
          renderAutoComplete={this.renderAutoComplete}
          updateUnreadMsgNumber={this.updateUnreadMsgNumber}
        />
      );
    }
    if (this.props.type === 'expandable') {
      return (
        <TypeHeadBubble
          nodeRef={this.nodeRef}
          message={this.state.message}
          focused={this.state.focused}
          settings={this.props.settings}
          widgetOpen={this.props.widgetOpen}
          newMessageCount={this.props.newMessageCount}
          waitingForSystemResponse={this.props.waitingForSystemResponse}
          placeholder={this.props.placeholder}
          hadConversation={this.props.hadConversation}
          typeheadRef={this.props.typeheadRef}
          downloadTranscript={this.props.downloadTranscript}
          onFocus={this.handleFocus}
          onChange={this.handleChange}
          onKeyUp={this.handleKeyPress}
          handleBlur={this.handleBlur}
          renderAudioMic={this.renderAudioMic}
          renderAutoComplete={this.renderAutoComplete}
          renderCustomAvatar={this.renderCustomAvatar}
          updateUnreadMsgNumber={this.updateUnreadMsgNumber}
          onDefaultTriggerClick={this.props.onDefaultTriggerClick}
        />
      );
    }
    return (
      <TypeHeadBase
        nodeRef={this.nodeRef}
        message={this.state.message}
        focused={this.state.focused}
        settings={this.props.settings}
        newMessageCount={this.props.newMessageCount}
        waitingForSystemResponse={this.props.waitingForSystemResponse}
        placeholder={this.props.placeholder}
        hadConversation={this.props.hadConversation}
        typeheadRef={this.props.typeheadRef}
        downloadTranscript={this.props.downloadTranscript}
        onFocus={this.handleFocus}
        onChange={this.handleChange}
        onKeyUp={this.handleKeyPress}
        handleBlur={this.handleBlur}
        renderAudioMic={this.renderAudioMic}
        renderAutoComplete={this.renderAutoComplete}
        renderCustomAvatar={this.renderCustomAvatar}
        updateUnreadMsgNumber={this.updateUnreadMsgNumber}
      />
    );
  }

  /**
   * Component's main render method - renders the conversation's input bar.
   * @returns {React.Node} Rendered content.
   */
  render() {
    return this.renderTypeHead();
  }
}

export default withLDConsumer()(TypeHead);
