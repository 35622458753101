// @flow
/* eslint-disable react/jsx-props-no-spreading */
/* eslint no-underscore-dangle: 0 */
/* eslint camelcase: 0 */

import React from 'react';
import Smooch from 'smooch';
import capitalize from 'lodash/capitalize';
import debounce from 'lodash/debounce';
import { ArrowDownOutlined } from '@ant-design/icons';
// $flow-disable
import { saveAs } from 'file-saver';
// $flow-disable
import { isSchedulingMessage, SystemMessage, gamTagToPlainText, parseGamTags } from 'gam-message-rendering';
import { setIdentity } from '../tracking';
import { clearStorageInIframe } from '../identityIndex';
import { catchAll, catchAllAsync } from '../utils/catchAll';
import { MESSAGE_BREAK, JOURNEY_TYPES, EMAIL_REQUEST_MESSAGES } from './data/general';
import { isClarifyingQuestion } from './utils/helpers';

import ConversationBaseChatClassic from './ConversationBaseChatClassic/ConversationBaseChatClassic';
import ConversationBaseChatBubble from './ConversationBaseChatBubble/ConversationBaseChatBubble';
import ConversationBaseChat from './ConversationBaseChat';

import {
  createDemoLinkClickedWebEvent,
  createDemoLinkOfferedWebEvent,
  createMessageReceivedWebEvent,
  createMessageSentWebEvent,
  createPageOpenedWebEvent,
  createPageClosedWebEvent,
  createVisitorIdleWebEvent,
  createWebSessionStartWebEvent,
  createVisitor,
  downloadTranscript,
  getUserJWT,
  pingProactive,
  getFeatureFlags,
  getTypeaheadSuggestions,
  getVisitor,
  getZendeskTicketFields,
  getCatalogSuggestionMatches,
  createZendeskTicket,
  createFeedback,
} from '../api';
import { AnalyticsEvents } from '../analytics';
import {
  getOrCreateUserId,
  isNotDuplicateLoad,
  getSessionId,
  fetchIdentity,
} from '../api/local';
import type {
  ButtonType,
  MessageType,
  SmoochMessageType,
  IframeMessageData,
} from '../entities';
import { PARENT_2_FRAME_COMMANDS } from '../iframeInterface';
import {
  type ConversationBaseProps,
  type ConversationBaseState,
} from './ComponentEntities/componentEntities';

// $flow-disable
import './ConversationBaseGlobal.scss';

/**
 * Returns boolean for the welcome messages visibility state.
 * @param {ConversationBaseState} state Component state
 * @param {ConversationBaseProps} props Component props
 * @returns {boolean} Show welcome messages
 */
function getWelcomeMessageVisibilityState(state, props) {
  const { settings } = props;
  const {
    widgetOpen,
    conversation,
    chatOpened,
    welcomeMessageBuffer,
    welcomeMessagesClosed,
    welcomeTimerComplete,
  } = state;
  const position = settings.design?.position || 'Center';

  if (position === 'Classic' || position === 'Expandable') {
    return (
      welcomeMessageBuffer.length
      && !widgetOpen
      && !welcomeMessagesClosed
    );
  }

  const lastMessage = conversation[conversation.length - 1];
  const welcomeMessageId = lastMessage
    && Object.keys(settings.welcomeMessages).find((key) => lastMessage.id.startsWith(`system-${key}`));

  return (
    !chatOpened
    && welcomeTimerComplete
    && welcomeMessageId
    && !welcomeMessagesClosed
    && welcomeMessageBuffer.length
  );
}

/**
 * Conversational Controller:
 * @param {ConversationBaseProps} props Conversation-base props
 * Controls the evolution of the conversational
 */
class ConversationBase extends React.Component<ConversationBaseProps, ConversationBaseState> {
  lastBotMessageTime: number;
  messageBuffer: Array<MessageType>;
  isRenderingFromMessageBuffer: boolean;
  sessionId: string;
  hiddenSmoochRef: any;
  proactiveTimeout: TimeoutID | null;
  messageSound: HTMLAudioElement;
  welcomeMessageDecisionTree: string | null;
  typeheadRef: any;

  /**
   * Constructor
   * @param {ConversationBaseProps} props - component props
   */
  constructor(props: ConversationBaseProps) {
    super(props);
    const newMessagesLocal = sessionStorage.getItem(`${props.botId}--newMessages`);
    this.state = {
      optionsOpen: false,
      widgetOpen: false,
      conversation: [],
      backdropStyle: {
        height: window.innerHeight,
        background: 'linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 185%)',
      },
      chatOpened: !!sessionStorage.getItem(`${props.botId}--chatUsed`) || false,
      focused: false,
      userId: null,
      appId: '',
      token: '',
      chatThreadRef: null,
      optionsRef: null,
      newMessageCount: +(sessionStorage.getItem(`${props.botId}--newMessageCount`) || 0),
      newMessages: newMessagesLocal ? JSON.parse(newMessagesLocal) : [],
      waitingForSystemResponse: false,
      ready: false,
      firstMessage: true,
      ticketFields: [],
      showResetCircle: false,
      smoochUserId: null,
      welcomeMessageBuffer: [],
      loadingConversation: false,
      waitingOnProactive: false,
      // Welcome Message State
      welcomeMessagesClosed: false,
      welcomeTimerComplete: false,
      // Visitor Journey State
      journeyType: localStorage.getItem(`${props.botId}--journeyType`) || null,
      supportJourneyFeatureFlag: false,
      typeaheadSuggestions: [],
      typeaheadCatalogSuggestions: [],
      parentHeight: null,
      parentWidth: null,
    };
    this.lastBotMessageTime = 0;
    this.messageBuffer = [];
    this.isRenderingFromMessageBuffer = false;
    this.hiddenSmoochRef = React.createRef();
    this.typeheadRef = React.createRef();
    this.proactiveTimeout = null;
    this.messageSound = new Audio('https://gam-bot-dist.s3.amazonaws.com/assets/button_click.mp3');

    this.initChatServerListeners(props);

    if (props.iframeInterface.inIFrame) {
      props.iframeInterface.addListener(
        PARENT_2_FRAME_COMMANDS.setParentDimensions,
        this.setParentDimensions,
      );
      props.iframeInterface.addListener(
        PARENT_2_FRAME_COMMANDS.close,
        this.setFocusStateClosed,
      );
    }
  }

  initChatServerListeners = (props: ConversationBaseProps) => {
    const { sessionId, isNew } = getSessionId(props.botId);
    this.sessionId = sessionId;
    if (isNew) {
      this.attemptToSendWebSessionStartEvent();
    }

    const { onError } = props.initHooks;

    // IO Server Listeners -> for now Smooch, in the future our own:
    props.chatServer.onMessageSent(catchAllAsync(onError, async (message) => {
      const { userId } = this.state;
      const { initHooks, dekuDomain, botId } = this.props;

      initHooks.onMessageSent(message.text);

      this.addUserMessageToConversation({
        id: `user-${message._id}`, // eslint-disable-line
        text: message.text,
      });

      await createMessageSentWebEvent(
        dekuDomain,
        botId,
        userId,
        props.chatServer.getUserId(),
        this.sessionId,
        props.iframeInterface,
      );
      sessionStorage.setItem(`${botId}--chatUsed`, 'true');
    }));

    props.chatServer.onTypingStart(catchAll(onError, () => {
      this.addLoadingMessageToConversation();
    }));

    props.chatServer.onTypingStop(catchAll(onError, () => {
      setTimeout(() => {
        this.setState(prevState => {
          const newConversation = [...prevState.conversation];
          const index = newConversation.findIndex(m => m.id === 'system-loading');
          if (index > -1) {
            newConversation.splice(index, 1);
          }
          return {
            waitingForSystemResponse: false,
            // conversation: newConversation,
          };
        });
      }, props.settings.botMessageDelay);
    }));

    // This event triggers when the user receives a message
    props.chatServer.onMessageReceived(catchAll(onError, (response: SmoochMessageType) => {
      const { userId, loadingConversation } = this.state;
      const {
        dekuDomain,
        botId,
        initHooks,
        iframeInterface,
        chatServer,
        analytics,
      } = this.props;

      if (response.metadata) {
        const { proactive, journey_type } = response.metadata;

        if (proactive) {
          this.setState({ waitingOnProactive: false });
        }

        if (journey_type) {
          localStorage.setItem(`${botId}--journeyType`, journey_type);
          this.setState({ journeyType: journey_type });
        }
      }

      // If the message contains Gamalon markup, track it with analytics
      if (response.text.includes('<GAM_')) {
        const [tagData] = parseGamTags([response.text]);
        analytics.track(
          AnalyticsEvents.VISITOR_RECEIVED_MARKUP,
          { type: tagData.type, text: response.text },
        );
      }

      const smoochReceivedCallback = () => {
        this.addSystemResponse(response);
        createMessageReceivedWebEvent(
          dekuDomain,
          botId,
          userId,
          chatServer.getUserId(),
          this.sessionId,
          iframeInterface,
        );
        initHooks.onMessageReceived(response.text);
      };

      if (loadingConversation) {
        this.setState({ loadingConversation: false }, smoochReceivedCallback);
      } else {
        smoochReceivedCallback();
      }
    }));
  };

  /**
   * React life cycle method.
   */
  componentWillUnmount() {
    const { chatServer, iframeInterface } = this.props;
    chatServer.destroy();
    iframeInterface.removeListener(this.setParentDimensions);
    iframeInterface.removeListener(this.setFocusStateClosed);
  }

  /**
   * React life cycle method.
   */
  async componentDidMount() {
    const {
      botId,
      dekuDomain,
      iframeInterface,
    } = this.props;

    const visitorId = await this.initComponentState();
    window.addEventListener('beforeunload', () => {
      // $flow-disable
      navigator.sendBeacon(`${dekuDomain}/deku/close_session?id=${this.sessionId}`);
      createPageClosedWebEvent(
        dekuDomain,
        botId,
        visitorId,
        null,
        this.sessionId,
        iframeInterface,
      );
    });

    window.DEBUG_MODE = () => {
      window.GAMALON_DEBUG_MODE_ACTIVE = true;
      this.setState({ showResetCircle: true });
    };
  }

  /**
   * React life cycle method.
   * @param {prevProps} prevProps previous to match against updated
   * @param {prevState} prevState previous to match against updated
   */
  componentDidUpdate(prevProps: ConversationBaseProps, prevState: ConversationBaseState) {
    if (this.props.settings !== prevProps.settings) {
      this.setBackdropStyle();
    }

    if (this.state.chatOpened && this.state.chatOpened !== prevState.chatOpened) {
      this.props.analytics.track(AnalyticsEvents.OPENED_BOT);
    }
  }

  initComponentState = async () => {
    const {
      dekuDomain,
      organizationId,
      botId,
      configId,
      iframeInterface,
      analytics,
    } = this.props;

    try {
      await fetchIdentity(organizationId, dekuDomain, botId);
    } catch (error) {
      console.error(error);
    }

    const sessionData = getSessionId(botId);
    this.sessionId = sessionData.sessionId;
    if (sessionData.isNew) {
      this.attemptToSendWebSessionStartEvent();
    }

    const { visitorId, isNew } = getOrCreateUserId(botId);

    analytics.identify(visitorId);
    iframeInterface.sendReadyMessage();
    iframeInterface.setLocalStorage({ [`${botId}--userId`]: visitorId });

    this.setState({ userId: visitorId }, async () => {
      if (isNew && visitorId) {
        await createVisitor(dekuDomain, visitorId);
        setIdentity(
          organizationId,
          dekuDomain,
          botId,
          visitorId,
          this.sessionId,
        );
      }

      if (organizationId === 'restorativebotanical') {
        try {
          const hideBot = (await analytics.waitForFeatureFlag('rb-show-noshow-2')) === 'control';
          console.log('hide for experiment:', hideBot);
          if (hideBot) {
            // Stop initialization
            return;
          }
        } catch (error) {
          console.error(error);
        }
      }

      await this.attemptToSendPageOpenedEvent();
      const { appId, token } = await getUserJWT(dekuDomain, visitorId, organizationId);

      try {
        const { data: { DEKU_VISITOR_JOURNEY } } = await getFeatureFlags(dekuDomain, token, botId);
        let ticketFields;

        if (DEKU_VISITOR_JOURNEY) {
          const { data } = await getZendeskTicketFields(dekuDomain, token, botId);
          ticketFields = data;
        } else {
          ticketFields = [];
        }

        const {
          data: typeaheadSuggestions,
        } = await getTypeaheadSuggestions(dekuDomain, token, botId, configId);

        this.setState({
          appId,
          token,
          ticketFields,
          supportJourneyFeatureFlag: DEKU_VISITOR_JOURNEY,
          typeaheadSuggestions,
        }, () => this.initChat());
      } catch (error) {
        console.warn('Error with pre-initialization api calls:', error);
        console.warn('Attempting to start bot anyway');
        this.setState({ appId, token }, () => this.initChat());
      }
    });

    return visitorId;
  };

  /**
   * @param {IframeMessageData} e - iframe message data
   */
  setParentDimensions = (e: IframeMessageData) => {
    this.setState({
      parentWidth: e.data.parentWidth,
      parentHeight: e.data.parentHeight,
    }, this.setBackdropStyle);
  }

  setFocusStateClosed = () => {
    this.handleConversationFocusState(false);
    this.setState({ widgetOpen: false });
  }

  attemptToSendWebSessionStartEvent = () => {
    const {
      dekuDomain,
      botId,
      iframeInterface,
    } = this.props;
    const { userId } = this.state;

    if (userId) {
      createWebSessionStartWebEvent(
        dekuDomain,
        botId,
        userId,
        null,
        this.sessionId,
        iframeInterface,
      );
    } else {
      setTimeout(() => {
        this.attemptToSendWebSessionStartEvent();
      }, 500);
    }
  }

  attemptToSendPageOpenedEvent = async () => {
    const {
      dekuDomain,
      botId,
      iframeInterface,
      rollbar,
    } = this.props;
    const { userId } = this.state;
    let { href } = window.location;
    if (iframeInterface.inIFrame) {
      href = iframeInterface.href;
    }

    if (href) {
      if (isNotDuplicateLoad(botId, href, rollbar)) {
        await createPageOpenedWebEvent(
          dekuDomain,
          botId,
          userId,
          null,
          this.sessionId,
          iframeInterface,
        );
      }
    } else {
      setTimeout(() => {
        this.attemptToSendPageOpenedEvent();
      }, 500);
    }
  }

  /**
  * Adds a bot response to the conversation. Deku sends a single response that
  * is to be divided into chat bubbles on the front end. The rules are as
  * follows:
  *
  * - Split the text by `MESSAGE_BREAK`. This divides the response into chucks
  * that share a database ID. The IDs are found in the `message_ids` of the
  * metadata.
  *
  * messages.length === messageIds.length
  * messageIds[i] is the ID for messages[i]
  *
  * - Within a message, there can be multiple paragraphs. These paragraphs each
  * need to be rendered as their own bubble. We split the messages by the `\n`
  * character. All the paragraphs share the same message ID.
  *
  * @param {SmoochMessageType} response - response to be added
  * @param {boolean} isNewMessage - new messages should be placed in the buffer.
  * Messages from convo history should be loaded immediately
  */
  addSystemResponse(response: SmoochMessageType, isNewMessage: boolean = true) {
    const { userId } = this.state;
    const { dekuDomain, botId, chatServer, iframeInterface } = this.props;
    const {
      actions = [],
      text,
      metadata = { message_ids: '[]' },
    } = response;
    // We need to account for the possibility that there are no message_ids
    const messageIds = JSON.parse(metadata.message_ids || '[]');
    const messages = text.split(MESSAGE_BREAK);

    if (messageIds.length !== messages.length) {
      console.warn('messageIds array is not the same length as messages array');
    }

    messages.forEach((message, i) => {
      const messageId = messageIds[i];
      const paragraphs = message.split('\n').filter(str => str.trim());

      // If buttons exists, always attach them on the last message.
      // TODO: Verify this heuristic
      const buttons = messages.length - 1 === i ? actions : null;

      paragraphs.forEach((paragraph, j) => {
        if (isSchedulingMessage(paragraph)) {
          if (isNewMessage) {
            createDemoLinkOfferedWebEvent(
              dekuDomain,
              botId,
              userId,
              chatServer.getUserId(),
              this.sessionId,
              iframeInterface,
            );
          }
        }

        const useBuffer = isNewMessage && !text.includes('GAM_DECISION_TREE_OPTION');
        this.addSystemMessageToConversation({
          id: `system-${response._id}-${i}-${j}`,
          text: paragraph,
          buttons: paragraphs.length - 1 === j ? buttons : null,
          messageId,
          metadata,
          responseId: response._id,
        }, useBuffer, isNewMessage);
      });
    });
  }

  /**
   * Start a chat instance.
   */
  initChat() {
    const { appId, userId, token } = this.state;
    const {
      chatServer,
      settings,
      analytics,
      configId,
      initHooks: { onError, onLoaded },
    } = this.props;
    const { delay } = settings.welcomeMessages;

    // render smooch instance to hidden DOM el
    // so that we can use our own UI -> idiosyncratic smooch hack.
    if (chatServer.name === 'smooch') {
      Smooch.render(this.hiddenSmoochRef.current);
    }

    chatServer.init(appId, userId, token)
      .then(() => {
        chatServer.onMessageAboutToSend(catchAll(onError, (message) => {
          const { firstMessage } = this.state;
          const metadata: any = {
            gamalonId: userId,
            sessionId: this.sessionId,
            firstMessage,
          };
          if (configId) {
            metadata.configId = configId;
          }
          const messageObj = {
            ...message,
            metadata,
          };
          if (firstMessage) {
            this.setState({ firstMessage: false });
          }
          return messageObj;
        }));

        // Here I'll just reveal the chat bar:
        onLoaded();
        this.setState({
          ready: true,
          smoochUserId: chatServer.getUserId(),
        });
        analytics.track(AnalyticsEvents.BOT_LOADED);
        this.addConversation();
        setTimeout(
          () => this.setState({ welcomeTimerComplete: true }),
          delay * 1000,
        );
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  /**
   * Send message to chat server
   * @param {string} message - user message from typehead
   */
  sendUserMessage = (message: string) => {
    const { chatServer, botId } = this.props;
    this.setState({ waitingForSystemResponse: true });
    chatServer.sendMessage(message);
    localStorage.setItem(`${botId}-returningUser`, 'true');
  }

  /**
   * Send payload message to chat server
   * @param {Object} button - button action to be sent
   */
  sendPayloadMessage = (button: ButtonType) => {
    if (this.state.waitingForSystemResponse) {
      return;
    }
    const { chatServer, botId } = this.props;
    this.setState({ waitingForSystemResponse: true });
    chatServer.sendMessage({
      type: 'text',
      text: button.payload,
      payload: button.payload,
    });
    localStorage.setItem(`${botId}-returningUser`, 'true');
  }

  /**
   * Adds previous conversation to chat thread. Filters out the messages that
   * have been cleared
   */
  addConversation() {
    this.setState({ loadingConversation: true }, () => {
      const { settings, chatServer, botId } = this.props;
      const { appId } = this.state;
      let isNewUser = true;
      const conversationCutoffId = localStorage.getItem(`${appId}-conversationCutoffId`);
      let clearingConversation = conversationCutoffId;
      const { messages } = chatServer.getConversation();

      const decisionTrees = new Set();

      messages.filter(({ _id }) => {
        isNewUser = false;
        localStorage.setItem(`${botId}-returningUser`, 'true');
        if (!clearingConversation) {
          return true;
        }

        if (conversationCutoffId === _id) {
          clearingConversation = false;
        }

        return false;
      }).forEach((response) => {
        if (response.text.startsWith('<GAM_DECISION_TREE')) {
          decisionTrees.add(response.text);
        }

        if (response.role === 'appUser') {
          this.addUserMessageToConversation({
            id: `user-${response._id}`,
            text: response.text,
            metadata: response.metadata,
          });
          return;
        }

        this.addSystemResponse(response, false);
      });

      let id;
      if (isNewUser) {
        id = 'intro';
      } else {
        id = 'return';
      }

      settings.welcomeMessages[id].forEach(({ text, url }, i) => {
        if (
          (window.location.href.includes(url)
            || !url
            || url.toLowerCase() === 'default')
            && text
        ) {
          if (text.startsWith('<GAM_DECISION_TREE')) {
            if (!decisionTrees.has(text)) {
              this.welcomeMessageDecisionTree = text;
            }
          } else {
            this.addSystemMessageToConversation({
              id: `system-${id}-${i}`,
              responseId: 'system-welcome-message',
              text,
            }, false, false);
          }
        }
      });
      this.setState({
        welcomeMessageBuffer: settings.welcomeMessages[id].map(({ text }) => text),
      });
    });
  }

  /**
   * Add a user message object to the conversation.
   * @param {Object} message - a system or user message entering the conversation
   */
  addUserMessageToConversation = (message: MessageType) => {
    this.setState(previousState => {
      const conversation = [...previousState.conversation];
      const index = conversation.findIndex(m => m.id === 'system-loading');
      if (index > -1) {
        conversation.splice(index, 1);
      }

      conversation.push(message);

      return { conversation };
    });
  }

  /**
   * Add a system message object to the message buffer and tried to add a
   * message from the buffer to the thread via addSystemMessageFromBuffer
   * @param {Object} newSystemMessage - a system message entering the conversation
   * @param {boolean} useBuffer - indicates if message should be put in buffer or
   * rendered immediately
   * @param {boolean} incrementCount - indicates if the message count indicator
   * should increment
   */
  addSystemMessageToConversation = (
    newSystemMessage: MessageType,
    useBuffer: boolean = true,
    incrementCount: boolean = true,
  ) => {
    if (useBuffer) {
      this.messageBuffer.push(newSystemMessage);
      this.addSystemMessageFromBuffer();
    } else {
      this.setState({ waitingForSystemResponse: false });

      if (incrementCount) {
        this.incrementNewMessageCount(newSystemMessage);
      }

      /**
       * Check if a typing indicator exists. If so, remove it.
       * Eitherway, add the newly received system message.
       */
      this.setState(prevState => {
        const newConversation = [...prevState.conversation];
        const index = newConversation.findIndex(message => message.id === 'system-loading');
        if (index > -1) {
          newConversation.splice(index, 1, newSystemMessage);
        } else {
          newConversation.push(newSystemMessage);
        }
        return { conversation: newConversation };
      });
    }
  };

  /**
   * If a message is not already in progress, set a 1.5 second timer to
   * rendering the next message in the buffer. After it renders, recursvely do
   * the same for the next message in the buffer.
   * @param {boolean} delay - indicates if message should be delayed or added immediately
   */
  addSystemMessageFromBuffer(delay: boolean = false) {
    const { botMessageDelay } = this.props.settings;

    if (this.isRenderingFromMessageBuffer || this.messageBuffer.length === 0) {
      return;
    }
    this.isRenderingFromMessageBuffer = true;
    const nextMessage = this.messageBuffer.shift();

    const normalTimeout = delay ? botMessageDelay * 1000 : 0;
    const timeout = nextMessage.text.startsWith('<GAM_DECISION_TREE_OPTION|') ? 1000 : normalTimeout;
    setTimeout(() => {
      this.setState({ waitingForSystemResponse: false });
      this.incrementNewMessageCount(nextMessage);

      /**
       * Check if a typing indicator exists. If so, remove it.
       * Eitherway, add the newly received system message.
       */
      this.setState(prevState => {
        if (this.props.settings.design.sound && !this.props.isPageVisible) {
          this.messageSound.play();
        }
        const newConversation = [...prevState.conversation];
        const index = newConversation.findIndex(message => message.id === 'system-loading');
        if (index > -1) {
          newConversation.splice(index, 1);
        }
        newConversation.push(nextMessage);
        return { conversation: newConversation };
      }, () => this.resetProactivePing());

      this.isRenderingFromMessageBuffer = false;
      this.addSystemMessageFromBuffer(true);
    }, timeout);
  }

  /**
   * Add a system message to thread that represents the system typing/loading.
   */
  addLoadingMessageToConversation = () => {
    const loadingMessageIndex = this.state.conversation.findIndex(m => m.id === 'system-loading');

    if (loadingMessageIndex === -1) {
      const loadingMessage = {
        id: 'system-loading', // eslint-disable-line
        text: '',
      };
      this.setState(previousState => {
        const conversation = [...previousState.conversation, loadingMessage];
        return {
          conversation,
          waitingForSystemResponse: true,
        };
      });
    }
  }

  /**
   * Increment the new message notification counter if the typehead isn't focused.
   * If object is a system message, increment the "new-message" counter
   * @param {MessageType} message - a system or user message entering the conversation
   */
  incrementNewMessageCount(message: MessageType) {
    const { settings, botId } = this.props;
    const isSystemMessage = message.id.includes('system');
    const isWelcomeMessage = Object.keys(settings.welcomeMessages).some(key => (
      message.id.startsWith(key)
    ));
    this.setState(prev => {
      let { newMessageCount, newMessages } = prev;
      if (
        (!isWelcomeMessage && isSystemMessage)
        // Either on another browser tab or chat bar is closed
        && (document.hidden || !prev.focused)
      ) {
        newMessageCount++;
        newMessages.push(message);
      } else {
        newMessageCount = 0;
        newMessages = [];
      }
      sessionStorage.setItem(`${botId}--newMessageCount`, `${newMessageCount}`);
      sessionStorage.setItem(`${botId}--newMessages`, JSON.stringify(newMessages));
      return { newMessageCount, newMessages };
    });
  }

  /**
   * Reset the new message counter to zero.
   */
  resetNewMessageCount = () => {
    const { botId } = this.props;

    this.setState({ newMessageCount: 0, newMessages: [] }, () => {
      sessionStorage.setItem(`${botId}--newMessageCount`, '0');
      sessionStorage.setItem(`${botId}--newMessages`, '[]');
    });
  }

  /**
   * set a reference to the chat thread component
   * @param {Object} chatThreadRef - object containing current
   * property of chat thread ref
   * @return {void}
   */
  setChatThreadRef = (chatThreadRef: any) => {
    this.setState({ chatThreadRef });
  }

  /**
   * set a reference to the options menu component
   * @param {Object} optionsRef - object containing current
   * property of options menu ref
   * @return {void}
   */
  setOptionsRef = (optionsRef: any) => {
    this.setState({ optionsRef });
  }

  /**
   * @returns {boolean} indicates if the last message is a question.
   */
  isLastMessageAQuestion= () => {
    const { conversation } = this.state;
    const lastMessage = conversation[conversation.length - 1];

    if (lastMessage && lastMessage.metadata) {
      const { proactive, button_template, cq, filtering } = lastMessage.metadata;
      const hasButtonTemplate = lastMessage.text.includes('<GAM_BUTTON|');
      return proactive || button_template || cq || filtering || hasButtonTemplate;
    }

    return false;
  }

  /**
   * Resets the timer that notifes the backend if the user is idle
   * @param {Function} isTypeAheadOpen - conditional to check if typeahead is open
   */
  resetProactivePing = (isTypeAheadOpen: Function = () => false) => {
    const {
      dekuDomain,
      botId,
      chatServer,
      settings,
      iframeInterface,
    } = this.props;
    const {
      conversation,
      waitingOnProactive,
      userId,
      journeyType,
      supportJourneyFeatureFlag,
    } = this.state;
    const { proactiveDelay } = settings;
    const lastMessage = conversation[conversation.length - 1];

    // Do not ping for proactive message the chat bar if...
    if (
      // ...there are no messages
      !lastMessage
      // ...the most recent message is a welcome message
      || lastMessage.id.startsWith('system-intro')
      || lastMessage.id.startsWith('system-return')
      // ...we are waiting on a response from deku
      || lastMessage.id === 'system-loading'
      || lastMessage.id.startsWith('user')
      // ...there are messages waiting to be rendered
      || this.messageBuffer.length
      // ...we are waiting on another proactive response
      || waitingOnProactive
      // ...if support flag is active AND the journey type is unset or support
      || lastMessage.id.startsWith('system-intro')
      // ...if last message is decision tree
      || lastMessage.text.includes('GAM_DECISION_TREE')
      || lastMessage.text.includes('GAM_DECISION_TREE_OPTION')
      // ...if last message is a question
      || this.isLastMessageAQuestion()
      || (
        supportJourneyFeatureFlag
          && (
            journeyType === JOURNEY_TYPES.support
            || journeyType === null
          )
      )
    ) {
      return;
    }
    if (this.proactiveTimeout) {
      clearTimeout(this.proactiveTimeout);
    }

    if (!isTypeAheadOpen()) {
      this.proactiveTimeout = setTimeout(() => {
        if (document.visibilityState === 'visible') {
          createVisitorIdleWebEvent(
            dekuDomain,
            botId,
            userId,
            chatServer.getUserId(),
            this.sessionId,
            iframeInterface,
          ).then(() => {
            this.setState({ waitingOnProactive: true });
            pingProactive(this.props.dekuDomain, this.state.token, this.props.configId);
          });
        }
      }, proactiveDelay * 1000);
    }
  }

  /**
   * Set the conversation's focus state
   * @param {boolean} focusState - focus state coming from focus state of typehead input
   */
  handleConversationFocusState = (focusState: boolean) => {
    const { welcomeMessagesClosed } = this.state;
    const { chatServer, iframeInterface, settings } = this.props;

    if (focusState && !welcomeMessagesClosed) {
      if (settings.design?.position !== 'Expandable') {
        this.closeWelcomeMessage();
      }
    }

    if (focusState && this.welcomeMessageDecisionTree) {
      chatServer.sendMessage(this.welcomeMessageDecisionTree);
      this.welcomeMessageDecisionTree = null;
    }

    this.setState((prevState) => ({
      focused: focusState,
      widgetOpen: focusState,
      chatOpened: focusState || prevState.chatOpened,
    }));

    if (focusState) {
      iframeInterface.sendOpenMessage();
    } else {
      iframeInterface.sendCloseMessage();
    }
  }

  /**
   * Closes welcome message by setting component and local state
   */
  closeWelcomeMessage = () => {
    this.setState({ welcomeMessagesClosed: true });
  }

  /**
   * Render debug circle
   * @returns {React.node} - html component
   */
  renderResetCircle = () => {
    const { dekuDomain } = this.props;
    const { appId, showResetCircle, smoochUserId } = this.state;

    if (showResetCircle && smoochUserId) {
      return (
        <div
          style={{
            width: 25,
            height: 25,
            borderRadius: 30,
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            position: 'fixed',
            bottom: 3,
            right: 3,
            zIndex: 10,
          }}
          onClick={async () => {
            const path = `/deku/apps/${appId}/users/${smoochUserId}/complete_all_threads`;
            const endpoint = `${dekuDomain}${path}`;
            await fetch(endpoint, { method: 'POST' });
          }}
        />
      );
    }

    return null;
  }

  /**
   * Renders the new messages (when the chat-bar is closed)
   * @param {HooksType} hooks - object containing hooks used by the SystemMessage
   * @returns {React.Node} Rendered content.
   */
  renderNewMessages = (hooks: any) => {
    const { settings, botId } = this.props;
    const { newMessages, widgetOpen, focused } = this.state;
    const position = settings.design ? settings.design.position : 'Center';
    if (newMessages.length === 0 || widgetOpen || focused) return null;
    return (
      <div className={`new-messages-container ${position}`}>
        <div className="new-messages-options">
          <div
            className="new-messages-trigger"
            onClick={() => {
              this.setState({
                widgetOpen: true,
                focused: true,
              }, () => {
                this.resetNewMessageCount();
              });
            }}
          >
            Open
          </div>
          <div
            className="new-messages-close"
            onClick={this.resetNewMessageCount}
          >
            <ArrowDownOutlined />
          </div>
        </div>
        <div className="shadow-top" />
        <div className="new-message-log">
          {newMessages.slice(-3).map(message => (
            <div key={message.id} className="new-message-entry">
              <SystemMessage
                id={message.id}
                text={message.text}
                isLastMessage
                settings={settings}
                hooks={hooks}
                ticketFields={[]}
                botId={botId}
              />
            </div>
          ))}
        </div>
        <div className="shadow-bottom" />
      </div>
    );
  };

  /**
   * Renders the welcome message
   * @param {HooksType} hooks - object containing hooks used by the SystemMessage
   * @returns {React.Node} Rendered content.
   */
  renderWelcomeMessage(hooks: any) {
    const { settings, botId, iframeInterface, analytics } = this.props;
    const { welcomeMessageBuffer } = this.state;
    const position = settings.design?.position || 'Center';
    const showWelcomeData = getWelcomeMessageVisibilityState(this.state, this.props);
    const revealedClass = showWelcomeData ? 'revealed' : '';

    if (showWelcomeData) {
      // We need to set the height of the iframe based on the height of the
      // welcome messages. The following code creates a height that is a rough
      // estimate
      // * The first 23px is for the close button
      // * Each new bubble is 47 pixels
      // * There are 45 characters per line. Each line, except the first one
      // (where the -1 comes from), adds 20 pixels to the height
      // * Images, video, and media cards add 168 px to the height
      iframeInterface.sendSetHeight(
        23 + (
          welcomeMessageBuffer.reduce((acc, val) => {
            const plainText = gamTagToPlainText(val);
            if (['[VIDEO]', '[IMAGE]'].includes(plainText) || val.startsWith('<GAM_MEDIA_CARD')) {
              return acc + 168;
            }

            if (plainText === '[FLOW]') {
              return acc;
            }

            return acc + Math.max(Math.round(plainText.length / 45) - 1, 0) * 20;
          }, welcomeMessageBuffer.length * 47)
        ),
      );
    } else {
      iframeInterface.sendSetHeight(null);
    }

    const messageOptions = position === 'Expandable' ? (
      <div className={`welcome-messages-options ${revealedClass}`}>
        <div
          className={`welcome-messages-trigger ${revealedClass}`}
          onClick={() => {
            this.setState({
              widgetOpen: true,
              focused: true,
            }, () => {
              this.resetNewMessageCount();
            });
          }}
        >
          Open
        </div>
        <div
          className={`welcome-messages-close ${revealedClass}`}
          onClick={this.closeWelcomeMessage}
        >
          <ArrowDownOutlined />
        </div>
      </div>
    ) : (
      <div
        className={`welcome-messages-close ${revealedClass}`}
        onClick={this.closeWelcomeMessage}
      >
        <ArrowDownOutlined />
      </div>
    );

    return (
      <div className={`welcome-messages-container ${position}`}>
        {messageOptions}
        {welcomeMessageBuffer.map((welcomeText) => (
          <div
            key={welcomeText}
            className={`welcome-message-container ${revealedClass}`}
            onClick={() => {
              analytics.track(AnalyticsEvents.CLICKED_WELCOME_MESSAGE);
              const [tagData] = parseGamTags([welcomeText]);
              if (tagData.type === 'text') {
                this.setState({
                  widgetOpen: true,
                  focused: true,
                }, () => {
                  this.resetNewMessageCount();
                });
              }
            }}
          >
            <SystemMessage
              id={welcomeText}
              text={welcomeText}
              isLastMessage
              settings={settings}
              hooks={hooks}
              ticketFields={[]}
              botId={botId}
            />
          </div>
        ))}
      </div>
    );
  }

  /**
   * Updates the gradient backdrop height.
   */
  setBackdropStyle = () => {
    const { settings } = this.props;
    const { backdropStyle, parentWidth, parentHeight } = this.state;
    const position = settings.design ? settings.design.position : 'Center';
    const responsiveMobileWidth = 577;
    const responsiveHeight = 500;
    const windowWidth = parentWidth === null ? window.innerWidth : parentWidth;
    const windowHeight = parentHeight === null ? window.innerHeight : parentHeight;

    let newBackdropHeight = backdropStyle.height;
    let gradient = 'linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 185%)';
    if (position === 'Center') {
      gradient = 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 185%)';
      if (windowHeight < responsiveHeight) {
        newBackdropHeight = windowHeight;
      } else {
        newBackdropHeight = windowHeight * 0.6;
      }
    } else if (windowWidth < responsiveMobileWidth) {
      gradient = 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 185%)';
      if (windowHeight < responsiveHeight) {
        newBackdropHeight = windowHeight;
      } else {
        newBackdropHeight = windowHeight * 0.6;
      }
    } else {
      newBackdropHeight = windowHeight;
    }

    this.setState({ backdropStyle: {
      height: newBackdropHeight,
      background: gradient,
    } });
  };

  /**
   * Fetches the match suggestions from Algolias FAQs
   * index and catalogs question generator.
   * @param {string} inputQuery Chat-bar input value
   */
  getTypeHeadCatalogSuggestions = async (inputQuery: string) => {
    if (inputQuery) {
      const { organizationId, botId, projectId } = this.props;
      const matchSuggestions = await getCatalogSuggestionMatches(
        inputQuery,
        projectId,
        organizationId,
        botId,
      );
      if (matchSuggestions?.result?.matches) {
        this.setState({
          typeaheadCatalogSuggestions: matchSuggestions.result.matches.map(
            (entry) => entry.question,
          ),
        });
      }
    }
  };

  /**
   * Downloads current conversations transcript
   */
  downloadChatTranscript = () => {
    const { token } = this.state;
    const { dekuDomain, organizationId, chatServer, botId } = this.props;
    const smoochConvo = chatServer.getConversation();
    const hadConversation = smoochConvo ? smoochConvo.messages.length > 0 : false;
    if (!hadConversation) {
      return;
    }
    downloadTranscript(dekuDomain, token, botId, chatServer.getUserId())
      .then(({ data }) => {
        // Capitalize the org name to include it in transcript
        const orgName = capitalize(organizationId);

        const downloadData = data.split('\n').map((line) => (
          line.replace(/^A: /, '\nVisitor: ').replace(/^B: /, `\n${orgName}: `)
        )).filter(line => line.trim()).join('\n');

        const blob = new Blob(
          [downloadData],
          { type: 'text/plain;charset=utf-8' },
        );

        saveAs(blob, 'chat_transcript.txt', { autoBom: true });
      })
      .catch(error => this.props.initHooks.onError(error.message));
  };

  /**
   * Ends current chat sesssion.
   */
  endChatSession = async () => {
    const { chatServer, botId } = this.props;
    clearStorageInIframe(botId);
    chatServer.destroy();
    await window.GAMALON.reload();
    this.initChatServerListeners(this.props);
    await this.initComponentState();
    setTimeout(() => {
      this.setState({
        newMessageCount: 0,
        newMessages: [],
        chatOpened: false,
        widgetOpen: false,
        focused: false,
        journeyType: null,
        conversation: [],
        welcomeMessagesClosed: false,
      });
    }, 1700);
  };

  /**
   * Returns chat-bar placeholder text.
   * @returns {string} Input placeholder
   */
  getPlaceholder = () => {
    const { settings, organizationId } = this.props;
    const { conversation, waitingForSystemResponse } = this.state;
    const lastMessage = conversation[conversation.length - 1];
    if (waitingForSystemResponse) {
      return '';
    }
    if (
      lastMessage === undefined
      || lastMessage.id.startsWith('system-intro')
      || lastMessage.id.startsWith('system-return')
    ) {
      if (settings.design?.placeholder) {
        return settings.design?.placeholder;
      }
      if (organizationId === 'vitalsleep') {
        return 'Have a question?';
      }
      return `Ask me questions about ${organizationId}.`;
    }
    if (EMAIL_REQUEST_MESSAGES.includes(lastMessage.text)) {
      return 'Give us your email or ask another question.';
    }
    if (isClarifyingQuestion(lastMessage)) {
      return 'Start typing...';
    }
    return 'Ask another question...';
  };

  /**
   * Returns hooks controller object
   * @returns {HooksType} Hooks object
   */
  getHooksObject = () => {
    const { token, userId } = this.state;
    const { dekuDomain, chatServer, botId, rollbar, iframeInterface, settings } = this.props;
    const position = settings.design?.position;
    return {
      createZendeskTicket: (data: any) => createZendeskTicket(dekuDomain, token, botId, data),
      getSchedulerFormFields: async () => {
        try {
          const {
            data: {
              email,
              clearbit,
            },
          } = await getVisitor(dekuDomain, token, botId, this.state.userId);
          const {
            person,
          } = clearbit || {};
          const {
            name,
          } = person || {};
          const {
            givenName: firstName,
            familyName: lastName,
          } = name || {};

          return {
            email,
            firstName,
            lastName,
            gamalon_visitor_id: this.state.userId,
          };
        } catch (error) {
          rollbar.warning('failed to fetch visitor for scheduling', error);
          return {
            gamalon_visitor_id: this.state.userId,
          };
        }
      },
      onSchedulerOpen: () => {
        if (this.typeheadRef?.current) {
          this.typeheadRef.current.blur();
        }
        createDemoLinkClickedWebEvent(
          dekuDomain,
          botId,
          userId,
          chatServer.getUserId(),
          this.sessionId,
          iframeInterface,
        );
      },
      onButtonClick: (button: any) => {
        if (position === 'Classic' || position === 'Expandable') {
          this.setState({
            widgetOpen: true,
          });
        }
        this.handleConversationFocusState(true);
        this.sendPayloadMessage(button);
      },
      onFeedbackButtonClick: async (feedback: string, messageId: string) => {
        await createFeedback(dekuDomain, token, messageId, feedback);
      },
      onModalChange: (showing: boolean) => {
        if (position !== 'Classic' && position !== 'Expandable') {
          if (showing) {
            iframeInterface.sendShowModalMessage();
          } else {
            iframeInterface.sendCloseModalMessage();
          }
        }
      },
    };
  };

  /**
   * Returns base properties object for the chat components.
   * @returns {Object} Base props object.
   */
  getConversationBaseProps = () => {
    const { chatServer } = this.props;
    const smoochConvo = chatServer.getConversation();
    const hadConversation = smoochConvo ? smoochConvo.messages.length > 0 : false;
    const hooks = this.getHooksObject();
    return {
      hooks,
      hadConversation,
      ready: this.state.ready,
      appId: this.state.appId,
      focused: this.state.focused,
      optionsRef: this.state.optionsRef,
      parentWidth: this.state.parentWidth,
      parentHeight: this.state.parentHeight,
      conversation: this.state.conversation,
      ticketFields: this.state.ticketFields,
      backdropStyle: this.state.backdropStyle,
      chatThreadRef: this.state.chatThreadRef,
      newMessageCount: this.state.newMessageCount,
      typeaheadSuggestions: this.state.typeaheadSuggestions,
      waitingForSystemResponse: this.state.waitingForSystemResponse,
      typeaheadCatalogSuggestions: this.state.typeaheadCatalogSuggestions,
      botId: this.props.botId,
      settings: this.props.settings,
      onError: this.props.initHooks.onError,
      iframeInterface: this.props.iframeInterface,
      typeheadRef: this.typeheadRef,
      hiddenSmoochRef: this.hiddenSmoochRef,
      organizationId: this.props.organizationId,
      placeholder: this.getPlaceholder(),
      newMessages: this.renderNewMessages(hooks),
      welcomeMessages: this.renderWelcomeMessage(hooks),
      setOptionsRef: this.setOptionsRef,
      sendUserMessage: this.sendUserMessage,
      setChatThreadRef: this.setChatThreadRef,
      renderResetCircle: this.renderResetCircle,
      resetProactivePing: this.resetProactivePing,
      downloadTranscript: this.downloadChatTranscript,
      resetNewMessageCount: this.resetNewMessageCount,
      isLastMessageAQuestion: this.isLastMessageAQuestion,
      resetConversation: () => this.setState({ conversation: [] }),
      handleConversationFocusState: this.handleConversationFocusState,
      getTypeHeadCatalogSuggestions: debounce(this.getTypeHeadCatalogSuggestions, 300),
      analytics: this.props.analytics,
    };
  };

  /**
   * Renders default form-factor chat component
   * @returns {React.Node} Rendered content.
   */
  renderBaseChatBar = () => (
    <ConversationBaseChat {...this.getConversationBaseProps()} />
  );

  /**
   * Renders "classic" form-factor chat component
   * @returns {React.Node} Rendered content.
   */
  renderClassicChatBar = () => (
    <ConversationBaseChatClassic
      {...this.getConversationBaseProps()}
      widgetOpen={this.state.widgetOpen}
      optionsOpen={this.state.optionsOpen}
      onActiveTriggerClick={() => {
        this.setState({
          widgetOpen: false,
        });
      }}
      onDefaultTriggerClick={() => {
        this.setState({
          widgetOpen: true,
        }, () => {
          this.handleConversationFocusState(true);
          this.resetNewMessageCount();
        });
      }}
      toggleOptions={() => {
        this.setState((prevState) => ({
          optionsOpen: !prevState.optionsOpen,
        }));
      }}
    />
  )

  /**
   * Renders "bubble" form-factor chat component
   * @returns {React.Node} Rendered content.
   */
  renderBubbleChatBar = () => (
    <ConversationBaseChatBubble
      {...this.getConversationBaseProps()}
      userId={this.state.userId || ''}
      widgetOpen={this.state.widgetOpen}
      onEndChatSession={this.endChatSession}
      onActiveTriggerClick={() => {
        this.setState({
          widgetOpen: false,
          focused: false,
        });
      }}
      onDefaultTriggerClick={() => {
        this.setState({
          widgetOpen: true,
          focused: true,
        }, () => {
          this.resetNewMessageCount();
        });
      }}
    />
  )

  /**
   * Renders the conversation chat component
   * @returns {React.Node} Rendered content.
   */
  renderConversationBaseChat = () => {
    const { settings } = this.props;

    if (settings.design?.position === 'Classic') {
      return this.renderClassicChatBar();
    }
    if (settings.design?.position === 'Expandable') {
      return this.renderBubbleChatBar();
    }
    return this.renderBaseChatBar();
  }

  /**
   * Component's main render method - renders the conversation thread and conversational input
   * @returns {React.Node} Rendered content.
   */
  render() {
    return this.renderConversationBaseChat();
  }
}

export default ConversationBase;
