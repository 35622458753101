// @flow
import React from 'react';
import Rollbar from 'rollbar';
import FireStoreParser from 'firestore-parser';
import merge from 'lodash/merge';
// $flow-disable
import PageVisibility from 'react-page-visibility';
import { Provider, ErrorBoundary } from '@rollbar/react';

import Analytics from './analytics';
import ConversationBase from './components/ConversationBase';
import SmoochChatServer from './SmoochChatServer';
import { getSettings } from './api';
import { hasCoverage } from './coverage';
import { compileWelcomeMessages } from './welcomeMessages';
import { LaunchDarklyProvider } from './LaunchDarklyContext';
import { IFrameParentInterface, PARENT_2_FRAME_COMMANDS } from './iframeInterface';

import type { ChatSettings, IframeMessageData, InitHooksType } from './entities';
import getUtmParameters from './UtmParameters';

// $flow-disable
import './App.scss';

type AppPropsType = {|
  botId: number,
  configId: ?number,
  dekuDomain: string,
  projectId: string,
  organizationId: string,
  gdprBannerSelector: string | typeof undefined,
  initHooks: InitHooksType,
|};

type AppStateType = {
  rollbar: any,
  settings: ChatSettings,
  hasSettings: boolean,
  error: string | null,
  gdprBannerOffsetStyle: any,
  hideBot: boolean, // used when page is displayed on chat preview
  isPageVisible: boolean,
  isMobile: boolean,
};

// From JQuery Source Code
const isHTMLElemVisible = (elem: any) => (
  !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length)
);

/**
* Renders the full Chat application
* @param {any} props - component properites: appId
* @returns {React.node} app markup
*/
class App extends React.Component<AppPropsType, AppStateType> {
  utmParameters: any;
  analytics: any;
  environment: string;
  iframeInterface: IFrameParentInterface;

  /**
   * React life cycle method.
   * @param {string} error - error as string
   * @return {Object} - component state
   */
  static getDerivedStateFromError(error: string) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  /**
   * Constructor
   * @param {Props} props - component props
   */
  constructor(props: AppPropsType) {
    super(props);

    const environment = ((url) => {
      switch (url) {
        case 'https://dev.gmln.io':
          return 'dev';
        case 'https://integration.gmln.io':
          return 'integration';
        case 'https://staging.gmln.io':
          return 'staging';
        case 'https://app.gamalon.com':
          return 'production';
        default:
          return 'local';
      }
    })(props.dekuDomain);

    const defaultState: ChatSettings = {
      coverage: [],
      design: {
        highlightColor: '',
        logo: '',
        position: 'Center',
      },
      welcomeMessages: {
        intro: [],
        return: [],
        delay: 5,
      },
      botMessageDelay: 1,
      proactiveDelay: 15,
    };

    this.state = {
      rollbar: new Rollbar({ // eslint-disable-line no-new
        accessToken: 'a10d105ece7b4b45af6e4f25b0b64b25',
        captureUncaught: false,
        captureUnhandledRejections: false,
        enabled: (environment !== 'local'),
        environment,
        payload: {
          organizationId: props.organizationId,
        },
      }),
      settings: defaultState,
      hasSettings: false,
      error: null,
      gdprBannerOffsetStyle: {},
      hideBot: false,
      isPageVisible: true,
      isMobile: false,
    };

    this.analytics = new Analytics(environment, props.botId, props.organizationId);
    this.analytics.page();

    this.utmParameters = getUtmParameters(props.botId);
    this.environment = environment;
    this.iframeInterface = new IFrameParentInterface();
  }

  /**
   * Handle window event
   * @param {Any} event window event
   */
  handleEvent = (event: any) => {
    if (event.data === 'HIDE_BOT') {
      this.setState({ hideBot: true });
    }
    if (typeof event.data === 'object') {
      if (event.data.type && event.data.type === 'PREVIEW_SETTINGS') {
        this.setState({ settings: event.data.settings });
      }
    }
  }

  /**
   * React life cycle method.
   */
  componentDidMount() {
    const { projectId, organizationId, botId } = this.props;
    // listen disable event
    window.addEventListener('message', this.handleEvent);
    this.iframeInterface.addListener(
      PARENT_2_FRAME_COMMANDS.setParentDimensions,
      this.setMobileFlag,
    );
    getSettings(projectId, organizationId, botId)
      .then(({ status, data }) => {
        if (status === 200) {
          this.setState((prevState) => ({
            hasSettings: true,
            settings: merge(prevState.settings, FireStoreParser(data.fields)),
          }), this.setupGDPRBannerOffset);
        }
      })
      .catch((e) => {
        this.setState({ hasSettings: true });
        console.error(
          'Conversational Agent is missing configuration settings.',
          e,
        );
      });
  }

  /**
   * React life cycle method.
   */
  componentWillUnmount() {
    window.removeEventListener('message', this.handleEvent);
    this.iframeInterface.removeListener(
      this.setMobileFlag,
    );
  }

  /**
  * @param {IframeMessageData} e - iframe data
  */
  setMobileFlag = (e: IframeMessageData) => {
    const { parentWidth } = e.data;
    const isMobile = parentWidth < 576;
    this.setState({ isMobile });
  }

  /**
   * The chat bar needs to be positioned in a way that takes into account the
   * GDPR banner. The user communicates the existence of a GDPR banner via the
   * config object 'cookieBanner' prop.
   *
   * The value of this prop will either be a querySelector OR the name of a
   * well-known GDPR provider. (Only 'hubspot' for now).
   *
   * We position the chatbar to rest just above the
   * banner. We assume the the banner is at the bottom of the page.
   */
  setupGDPRBannerOffset = () => {
    const { state, props } = this;
    const gdprBannerSelector = props.gdprBannerSelector || state.settings?.cookieBanner;

    if (gdprBannerSelector) {
      // Handle the hubspot case
      if (gdprBannerSelector === 'hubspot') {
        const domEle = document.querySelector('div#hs-eu-cookie-confirmation');
        if (!domEle) {
          // We want to alert roll
          this.state.rollbar.error('Hubspot GDPR banner not found');
          console.error('Hubspot GDPR banner not found');
          return;
        }
        const { bottom } = window.getComputedStyle(domEle);
        // Hubspot banners can responsivley rest at the top or bottom of the
        // screen. We only need to set a banner offset if the banner is at the
        // bottom of the screen.
        if (bottom === '0px') {
          window.addEventListener('click', (e) => {
            const clickedButton = e.path.some(p => (
              p.id === 'hs-eu-confirmation-button' || p.id === 'hs-eu-decline-button'
            ));
            if (clickedButton) {
              this.setState({ gdprBannerOffsetStyle: {} });
            }
          });
          this.setState({ gdprBannerOffsetStyle: { bottom: 100 } });
        }
        return;
      }

      // Handle the query selector case
      let bannerParentObserver;
      const bannerEle = document.querySelector(gdprBannerSelector);
      if (bannerEle) {
        // Observe if the style of the banner changes in a way that makes the DOM element invisible
        const bannerObserver = new MutationObserver((e) => {
          if (!isHTMLElemVisible(e[0].target)) {
            this.setState({ gdprBannerOffsetStyle: {} });
            bannerObserver.disconnect();
            if (bannerParentObserver) {
              bannerParentObserver.disconnect();
            }
          }
        });
        bannerObserver.observe(bannerEle, { attributes: true, attributeFilter: ['style'] });
        if (bannerEle.parentNode) {
          // Observe if the the banner element is removed from DOM
          bannerParentObserver = new MutationObserver((e) => {
            if (e[0].removedNodes[0] === bannerEle) {
              this.setState({ gdprBannerOffsetStyle: {} });
              bannerParentObserver.disconnect();
              bannerObserver.disconnect();
            }
          });
          bannerParentObserver.observe(bannerEle.parentNode, { childList: true });
        }
        // Set the chatbar just above the banner
        const { height, marginTop, marginBottom } = window.getComputedStyle(bannerEle);
        this.setState({
          gdprBannerOffsetStyle: {
            bottom: parseInt(height, 10) + parseInt(marginTop, 10) + parseInt(marginBottom, 10),
          },
        });
      }
    }
  }

  /**
   * Handle page visibility changes
   * @param {boolean} isPageVisible is page visible
   */
  handleVisibilityChange = (isPageVisible: boolean) => {
    this.setState({ isPageVisible });
  }

  /**
   * Component's main render method - renders the conversation thread and conversational input
   * @returns {React.Node} Rendered content.
   */
  render() {
    const { dekuDomain, organizationId, botId, configId, projectId } = this.props;
    const {
      settings,
      hasSettings,
      error,
      gdprBannerOffsetStyle,
      hideBot,
      isPageVisible,
      isMobile,
    } = this.state;
    let layoutClass = settings.design ? settings.design.position : 'Center';
    layoutClass += (isMobile ? ' iframe-mobile' : '');
    const { href } = window.location;
    const pageAllowed = hasCoverage(settings, href);
    const showBot = hasSettings && pageAllowed;

    if (error) {
      this.props.initHooks.onError(error);
      this.state.rollbar.error(error);
      return null;
    }

    if (hasSettings && !pageAllowed) {
      console.warn('FORBIDDEN: Cannot show conversational agent on this page.');
    }

    if (hideBot) {
      return null;
    }

    if (!showBot) {
      return null;
    }

    const visitorData = {
      url: window.location.protocol + '//' + window.location.host + window.location.pathname,
      newOrReturning: 'New',
    };

    // Create a modified initHooks object where the onError handler sends
    // errors to Rollbar, in addition to whatever else it would do.
    const innerInitHooks = {
      ...this.props.initHooks,
      onError: (message: string) => {
        this.state.rollbar.error(message);
        this.props.initHooks.onError(message);
      },
    };

    compileWelcomeMessages(settings, this.utmParameters, visitorData);
    return (
      <Provider instance={this.state.rollbar} config={{}}>
        <ErrorBoundary extra={err => this.props.initHooks.onError(err.message)}>
          <div id="g-app" className={`g-app g-app--${layoutClass}`} style={gdprBannerOffsetStyle}>
            <LaunchDarklyProvider
              organization={this.props.organizationId}
              env={this.environment}
            >
              <PageVisibility onChange={this.handleVisibilityChange}>
                <ConversationBase
                  settings={settings}
                  dekuDomain={dekuDomain}
                  organizationId={organizationId}
                  botId={botId}
                  configId={configId}
                  projectId={projectId}
                  chatServer={SmoochChatServer}
                  analytics={this.analytics}
                  isPageVisible={isPageVisible}
                  rollbar={this.state.rollbar}
                  initHooks={innerInitHooks}
                  iframeInterface={this.iframeInterface}
                />
              </PageVisibility>
            </LaunchDarklyProvider>
          </div>
        </ErrorBoundary>
      </Provider>
    );
  }
}

export default App;
