// @flow
import uuid4 from 'uuid/v4';
import queryString from 'query-string';

import type { IframeMessageData } from './entities';

export const FRAME_2_PARENT_COMMANDS = {
  ready: 'ready',
  open: 'open',
  close: 'close',
  showModal: 'showModal',
  closeModal: 'closeModal',
  setHeight: 'setHeight',
  setLocalStorage: 'setLocalStorage',
  setCentered: 'setCentered',
};

export const PARENT_2_FRAME_COMMANDS = {
  setParentDimensions: 'setParentDimensions',
  close: 'close',
  setLocationData: 'setLocationData',
};

/**
* This class is for interfacing the the iframe parent
*/
export class IFrameParentInterface {
    id: string;
    source: string;
    ready: boolean;
    inIFrame: boolean;
    href: string | null;
    referrer: string | null;
    callbacks: Array<{
        cb: any => any,
        callback: any => any,
    }>;

    /** Constructor */
    constructor() {
      this.id = uuid4();
      this.source = `gamalon-${this.id}`;
      this.ready = false;
      this.callbacks = [];
      this.inIFrame = queryString.parse(window.location.search).type === 'iframe';
      this.href = null;
      this.referrer = null;

      this.addListener(
        PARENT_2_FRAME_COMMANDS.setLocationData,
        this.setLocationData,
      );
    }

    /**
    * @param {IframeMessageData} e - message event data
    */
    setLocationData = (e: IframeMessageData) => {
      this.href = e.data.href;
      this.referrer = e.data.referrer;
    };

    sendReadyMessage = () => {
      window.parent.postMessage({
        source: this.source,
        cmd: FRAME_2_PARENT_COMMANDS.ready,
        data: {},
      }, '*');
    };

    sendOpenMessage = () => {
      window.parent.postMessage({
        source: this.source,
        cmd: FRAME_2_PARENT_COMMANDS.open,
        data: {},
      }, '*');
    };

    sendCloseMessage = () => {
      window.parent.postMessage({
        source: this.source,
        cmd: FRAME_2_PARENT_COMMANDS.close,
        data: {},
      }, '*');
    };

    sendShowModalMessage = () => {
      window.parent.postMessage({
        source: this.source,
        cmd: FRAME_2_PARENT_COMMANDS.showModal,
        data: {},
      }, '*');
    };

    sendCloseModalMessage = () => {
      window.parent.postMessage({
        source: this.source,
        cmd: FRAME_2_PARENT_COMMANDS.closeModal,
        data: {},
      }, '*');
    };

    sendCenteredNotification = () => {
      window.top.postMessage({
        source: this.source,
        cmd: FRAME_2_PARENT_COMMANDS.setCentered,
        data: {},
      }, '*');
    };

    /**
    * @param {number | null} height - the height we should add tothe iframe.
    * null means remove all extra height
    */
    sendSetHeight = (height: number | null) => {
      window.parent.postMessage({
        source: this.source,
        cmd: FRAME_2_PARENT_COMMANDS.setHeight,
        data: {
          height,
        },
      }, '*');
    };

    /**
    * @param {Object} data - localstorage data to be set.
    */
    setLocalStorage = (data: Object) => {
      window.parent.postMessage({
        source: this.source,
        cmd: FRAME_2_PARENT_COMMANDS.setLocalStorage,
        data,
      }, '*');
    };

    /**
    * @param {string} cmd - the command the callback is listening on
    * @param {Function} cb - the callback
    */
    addListener = (cmd: string, cb: Function) => {
      if (PARENT_2_FRAME_COMMANDS[cmd]) {
        const callback = (e) => {
          if (e.data.source === this.source && e.data.cmd === cmd) {
            cb(e.data);
          }
        };
        // In addition to attaching the newly constructed callback, we want to
        // store in along with the cb arugment. It's used in #removeListener
        this.callbacks.push({ cb, callback });
        window.addEventListener('message', callback);
      }
    }

    /**
    * @param {Function} cb - the callback to remove
    */
    removeListener = (cb: any => any) => {
      const cbData = this.callbacks.find(d => d.cb === cb);
      if (cbData) {
        window.removeEventListener('message', cbData.callback);
      }
    }
}
