// This is the code that is in the identity iframe. It emitted and listens for
// messages for setting and retriving the visitor id
// @flow

import { IdentityTrackingCommands, clearStorage } from './tracking';
import { createWebSessionEndWebEvent } from './api';

let visitorId = null;
let sessionId = null;
const getVisitorStorageKey = botId => `gam-${botId}-identity`;
const getSessionStorageKey = botId => `gam-${botId}-session`;

/**
* Sets the visitorId to the value in localStorage. If it doesn't exist, it
* creates a null visitor-id
* @param {number} botId - used for localStorage key
*/
const getIdentity = (botId: number) => {
  try {
    const storageKey = getVisitorStorageKey(botId);
    const identity = localStorage.getItem(storageKey);
    if (!visitorId) {
      if (identity) {
        visitorId = identity;
        localStorage.setItem(storageKey, visitorId);
      }
    }
  } catch (error) {
    console.error(error);
  }
};

/**
* Sets the sessionID to the value in sessionStorage. If it doesn't exist, it
* creates a null session-id
* @param {number} botId - used for sessionStorage key
*/
const getSession = (botId: number) => {
  try {
    const storageKey = getSessionStorageKey(botId);
    const session = sessionStorage.getItem(storageKey);
    if (!sessionId) {
      if (session) {
        sessionId = session;
        sessionStorage.setItem(storageKey, sessionId);
      }
    }
  } catch (error) {
    console.error(error);
  }
};

const setSessionId = (data) => {
  try {
    const sessionIdKey = getSessionStorageKey(data.botId);
    const visitorIdKey = getVisitorStorageKey(data.botId);
    const visitor = localStorage.getItem(visitorIdKey);
    const prevSessionId = sessionStorage.getItem(sessionIdKey);
    if (prevSessionId && visitor && prevSessionId !== data.sessionId) {
      createWebSessionEndWebEvent(
        data.domain,
        data.botId,
        visitor,
        null,
        prevSessionId,
      );
    }
    sessionId = data.sessionId;
    sessionStorage.setItem(sessionIdKey, sessionId);
  } catch (error) {
    console.error(error);
  }
};

/**
* sends visitor id to parent page
* @param {number} botId - used for localStorage key
*/
const getAndSendIdentity = (botId: number) => {
  getIdentity(botId);
  getSession(botId);
  window.parent.postMessage({
    source: 'gamalon-identity',
    cmd: IdentityTrackingCommands.SEND_IDENTITY_TO_PARENT,
    data: { visitorId, sessionId },
  }, '*');
};

export const clearStorageInIframe = (botId: number) => {
  console.log('Clearing in iframe');
  clearStorage(botId);
  window.parent.postMessage({
    source: 'gamalon-identity',
    cmd: IdentityTrackingCommands.STORAGE_CLEARED,
    data: {},
  }, '*');
  console.log('Cleared');
};

window.addEventListener('message', ({ data: { source, cmd, data } }) => {
  if (source === 'gamalon-identity') {
    switch (cmd) {
      case IdentityTrackingCommands.SEND_VISITOR_ID_TO_IFRAME:
        visitorId = data.visitorId;
        localStorage.setItem(
          getVisitorStorageKey(data.botId),
          visitorId,
        );
        break;
      case IdentityTrackingCommands.SEND_SESSION_ID_TO_IFRAME:
        setSessionId(data);
        break;
      case IdentityTrackingCommands.IDENTIFY:
        getAndSendIdentity(data.botId);
        break;
      case IdentityTrackingCommands.CLEAR_STORAGE:
        clearStorageInIframe(data.botId);
        break;
      default:
        break;
    }
  }
});

// Give the parent some time to listen to the READY event
setTimeout(() => {
  window.parent.postMessage({
    source: 'gamalon-identity',
    cmd: IdentityTrackingCommands.READY,
    data: {},
  }, '*');
}, 500);
