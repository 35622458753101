// @flow

type AnyFunction = (...args: Array<any>) => any;
type AnyAsyncFunction = (...args: Array<any>) => Promise<any>;

/**
 * Returns a function that wraps an inner function in a try...catch clause and
 * calls an error callback function if it throws an exception. Other than this
 * the returned function acts exactly like the wrapped function, taking the
 * same arguments and returning the same value. It rethrows the exception if
 * it's caught.
 * @param {string} onError The function to call if an exception is caught. It
 * is passed the exception's message property as an argument.
 * @param {FuncType} innerFunc The inner function to wrap.
 * @returns {FuncType} The wrapped function.
 */
export function catchAll<FuncType: AnyFunction>(
  onError: string => any,
  innerFunc: FuncType,
): FuncType {
  // Not sure why Flow doesn't allow this. Possibly it fails to recognize that
  // the returned function has equivalent type to innerFunc.
  // https://stackoverflow.com/q/69005241/2501624
  // $flow-disable
  return (...args) => {
    try {
      return innerFunc.apply(this, args);
    } catch (error) {
      onError(`${error.message}\n${error.stack}`);
      throw error;
    }
  };
}

/**
 * Async version of catchAll above. Returns an async function that wraps an
 * inner async function in a try...catch clause and calls an error callback
 * function if it throws an exception (that is, if its returned Promise is
 * rejected). Other than this the returned function acts exactly like the
 * wrapped function, taking the same arguments and returning the same value. It
 * rethrows the exception if it's caught.
 * @param {string} onError The function to call if an exception is caught. It
 * is passed the exception's message property as an argument.
 * @param {FuncType} innerFunc The inner function to wrap.
 * @returns {FuncType} The wrapped function.
 */
export function catchAllAsync<FuncType: AnyAsyncFunction>(
  onError: string => any,
  innerFunc: FuncType,
): FuncType {
  // see comment in other function above
  // $flow-disable
  return async (...args) => {
    try {
      return await innerFunc.apply(this, args);
    } catch (error) {
      onError(`${error.message}\n${error.stack}`);
      throw error;
    }
  };
}
