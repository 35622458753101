// @flow
import escapeRegExp from 'lodash/escapeRegExp';
import { type ChatSettings } from './entities';

export const COVERAGE_RULES = {
  Is: 'Is:',
  IsNot: 'Is not:',
  StartsWith: 'Starts with:',
  EndsWith: 'Ends with:',
  Contains: 'Contains:',
  DoesNotContain: 'Does not contain:',
  HasAnyValue: 'Has any value.',
};

export const DISPLAY_CONDITIONS = {
  Includes: 'includes',
  NotIncludes: 'not_includes',
  ExactlyMatches: 'exactly_matches',
  NotExactlyMatches: 'not_exactly_matches',
  StartsWith: 'starts_with',
  EndsWith: 'ends_with',
};

const conditionMatches = (condition, pageUrl) => {
  const escapedUrl = escapeRegExp(condition.value);
  const isRegExp = new RegExp('^' + escapedUrl + '$');
  const startsWithRegExp = new RegExp('^' + escapedUrl);
  const endsWithRegExp = new RegExp(escapedUrl + '$');
  const containsRegExp = new RegExp(escapedUrl);

  if (
    condition.type === DISPLAY_CONDITIONS.Includes
    && containsRegExp.test(pageUrl)
  ) {
    return true;
  }

  if (
    condition.type === DISPLAY_CONDITIONS.NotIncludes
    && !containsRegExp.test(pageUrl)
  ) {
    return true;
  }

  if (
    condition.type === DISPLAY_CONDITIONS.ExactlyMatches
    && isRegExp.test(pageUrl)
  ) {
    return true;
  }

  if (
    condition.type === DISPLAY_CONDITIONS.NotExactlyMatches
    && !isRegExp.test(pageUrl)
  ) {
    return true;
  }

  if (
    condition.type === DISPLAY_CONDITIONS.StartsWith
    && startsWithRegExp.test(pageUrl)
  ) {
    return true;
  }

  if (
    condition.type === DISPLAY_CONDITIONS.EndsWith
    && endsWithRegExp.test(pageUrl)
  ) {
    return true;
  }

  return false;
};

const allConditionsMatch = ({ conditions }, pageUrl) => (
  conditions.every((condition) => (
    conditionMatches(condition, pageUrl)))
);

const isDisplayLocation = (rules, defaultDisplay, pageUrl) => {
  if (!rules) {
    return defaultDisplay;
  }

  for (let i = 0; i < rules.length; i++) {
    const rule = rules[i];
    if (allConditionsMatch(rule, pageUrl)) {
      return rule.display;
    }
  }
  return defaultDisplay;
};

export const hasCoverage = (
  settings: ChatSettings,
  pageUrl: string,
) => {
  const { coverage, displayLocationRules, displayLocationElseRule } = settings;
  if (displayLocationRules?.length || displayLocationElseRule !== undefined) {
    return isDisplayLocation(
      displayLocationRules,
      displayLocationElseRule ?? true,
      pageUrl,
    );
  }

  // If there are no coverage rules, show bot everywhere
  if (!coverage || !coverage.length) {
    return true;
  }

  const activeRules = coverage.filter(c => c.active);

  let showBot = false;

  for (let i = 0; i < activeRules.length; i += 1) {
    const { rule, url } = activeRules[i];
    const escapedUrl = escapeRegExp(url);
    const isRegExp = new RegExp('^' + escapedUrl + '$');
    const startsWithRegExp = new RegExp('^' + escapedUrl);
    const endsWithRegExp = new RegExp(escapedUrl + '$');
    const containsRegExp = new RegExp(escapedUrl);

    // Show bot if HasAnyValue rule is active OR Is rule has an exact match
    if ((rule === COVERAGE_RULES.Is && isRegExp.test(pageUrl))
      || (rule === COVERAGE_RULES.HasAnyValue)
    ) {
      return true;
    }

    // Do NOT show bot IsNot rule has an exact match or DoesNotContain has a match
    if ((rule === COVERAGE_RULES.IsNot && isRegExp.test(pageUrl))
      || (rule === COVERAGE_RULES.DoesNotContain && containsRegExp.test(pageUrl))
    ) {
      return false;
    }

    // Show bot if url StartsWith, EndsWith, or Contains the url. We do not
    // immediately return true in case there is a match for an IsNot or
    // DoesNotContain rule
    if ((rule === COVERAGE_RULES.StartsWith && startsWithRegExp.test(pageUrl))
      || (rule === COVERAGE_RULES.EndsWith && endsWithRegExp.test(pageUrl))
      || (rule === COVERAGE_RULES.Contains && containsRegExp.test(pageUrl))
    ) {
      showBot = true;
    }
  }

  return showBot;
};
