// This list must be coordinated with the collection of canned messages in deku
// See `CannedMessageCategory.PROMPT_FOR_EMAIL` in deku
export const EMAIL_REQUEST_MESSAGES = [
  'If you are willing to provide your email, I will send you answers to your questions and a copy of this chat.',
  'Would you mind sharing your email so I can ensure you get what you need?',
  'What\'s the best email address to reach you?',
  'What email address would you like me to use to send you the appropriate response?',
  'Mind sharing your email with me?',
];
export const MESSAGE_BREAK = '<GAM_MESSAGE_BREAK>';

export const JOURNEY_TYPES = {
  support: 'support',
  sales: 'sales',
};
