// @flow
import React, { useEffect } from 'react';

// $flow-disable
import { ReactComponent as TriggerLogoDefault } from '../../assets/images/trigger-logo-default';
// $flow-disable
import { ReactComponent as TriggerLogoActive } from '../../assets/images/trigger-logo-active';

// $flow-disable
import './ChatTriggerWidget.scss';

type Props = {
  widgetOpen: boolean,
  newMessageCount: number,
  companyLogo: string,
  onActiveTriggerClick: () => void,
  onDefaultTriggerClick: () => void,
  resetNewMessageCount: () => void,
  color: string,
};

const ChatTriggerWidget = ({
  widgetOpen,
  newMessageCount,
  companyLogo,
  onActiveTriggerClick,
  onDefaultTriggerClick,
  resetNewMessageCount,
  color,
}: Props) => {
  useEffect(() => {
    if (!document.hidden && widgetOpen) {
      resetNewMessageCount();
    }
  }, [document.hidden]);

  /**
   * Update chat widget and browser title with number of unread messages.
   * @returns {JSX.Element} Number on the right side of input bar,
   * indicating unread messages.
   */
  const updateUnreadNumber = () => (
    <span className="chat-widget-trigger__notification">
      {newMessageCount}
    </span>
  );

  const renderDefaultTrigger = () => {
    if (companyLogo) {
      return (
        <div
          className="chat-widget-trigger__logo logo-default logo-company"
          style={{ backgroundImage: `url(${companyLogo})` }}
          onClick={onDefaultTriggerClick}
        />
      );
    }

    return (
      <div
        className="chat-widget-trigger__logo logo-default logo-company"
        onClick={onDefaultTriggerClick}
      >
        <TriggerLogoDefault
          color={color}
        />
      </div>
    );
  };

  return (
    <div
      className={`chat-widget-trigger ${widgetOpen ? '__triger-open' : '__triger-closed'}`}
    >
      <div
        className="chat-widget-trigger__logo logo-active"
        onClick={onActiveTriggerClick}
      >
        <TriggerLogoActive
          color={color}
        />
      </div>
      { renderDefaultTrigger() }
      {newMessageCount ? updateUnreadNumber() : null}
    </div>
  );
};

export default ChatTriggerWidget;
