// @flow
import React from 'react';
// $flow-disable
import ScrollContainer from 'react-indiana-drag-scroll';

// $flow-disable
import { Thread, isZendeskForm } from 'gam-message-rendering';

import {
  type ChatSettings,
  type MessageType,
  type ButtonType,
} from '../../entities';
import Analytics, { AnalyticsEvents } from '../../analytics';
import { IFrameParentInterface } from '../../iframeInterface';

// $flow-disable
import './ChatThread.scss';

type ChatThreadProps = {|
  settings: ChatSettings,
  conversation: Array<MessageType>,
  conversationFocusedState: boolean,
  setChatThreadRef: any => any,
  ticketFields: Array<any>,
  botId: number,
  hooks: {
    onButtonClick: ButtonType => void,
    onSchedulerOpen: void => void,
    createZendeskTicket: any => Promise<any>,
    onModalChange: boolean => void,
  },
  iframeInterface?: IFrameParentInterface,
  userId?: string,
  parentHeight: number | null,
  parentWidth: number | null,
  analytics: Analytics,
|};

type ChatThreadState = {
  threadHeight: number,
  threadKey: string,
};

// $flow-disable
/**
 * All messages shown in the user-system conversation
 * @param {ChatThreadProps} props props
 */
class ChatThread extends React.Component<ChatThreadProps, ChatThreadState> {
  messagesEndRef: any;
  nodeRef: any;
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    userId: undefined,
    iframeInterface: undefined,
    parentHeight: null,
    parentWidth: null,
  };

  /**
   * Constructor
   */
  constructor() {
    super();
    this.state = {
      threadKey: 'initial-key',
      threadHeight: (window.innerHeight * 0.6) - (65 + 30),
    };

    this.messagesEndRef = React.createRef();
    this.nodeRef = React.createRef();
  }

  /**
   * React life cycle method.
   */
  componentDidMount() {
    this.scrollToBottom();
    this.props.setChatThreadRef(this.nodeRef);
    this.setChatThreadHeight();
    window.addEventListener('resize', () => {
      this.setChatThreadHeight();
    });
  }

  /**
   * React life cycle method.
   * @param {ChatThreadProps} prevProps previous to match against updated
   */
  componentDidUpdate(prevProps: ChatThreadProps) {
    const { conversationFocusedState, conversation, settings, userId } = this.props;

    if (
      prevProps.conversationFocusedState !== conversationFocusedState
      && conversationFocusedState
    ) {
      this.scrollToBottom();
    }

    const prevLastMessage = prevProps.conversation[prevProps.conversation.length - 1];
    const lastMessage = conversation[conversation.length - 1];

    setTimeout(() => {
      if (
        (
          prevProps.conversation.length !== conversation.length
          || prevLastMessage?.id !== lastMessage?.id
        )
        && (
          (prevLastMessage?.id === 'system-loading' && !lastMessage.id.startsWith('user'))
          || (prevLastMessage?.id !== 'system-loading' && !lastMessage?.id !== 'system-loading')
        )
      ) {
        // If the last message is a form, we only want to scroll down 125 pixels
        if (lastMessage && isZendeskForm(lastMessage.text) && this.nodeRef?.current) {
          const chatContainer = this.nodeRef.current;
          const scrollContainer = chatContainer.children[chatContainer.children.length - 1];
          const innerContainer = scrollContainer && scrollContainer[scrollContainer.length - 1];
          if (innerContainer?.scrollBy) {
            innerContainer.scrollBy({
              left: 0,
              top: 125,
              behavior: 'smooth',
            });
          } else {
            this.scrollToBottom();
          }
        } else {
          this.scrollToBottom();
        }
      }
    }, 200);

    if (userId && userId !== prevProps.userId) {
      setTimeout(() => {
        this.setChatThreadHeight();
        this.setState({ threadKey: userId });
      }, 1800);
    }

    if (settings !== prevProps.settings) {
      this.setChatThreadHeight();
    }
  }

  /**
  * @param {boolean} autoBehavior - should the scroll behavior be set to 'auto'. Defaults
  * to false, meaning the behavior will be smooth
  */
  scrollToBottom = (autoBehavior: boolean = false) => {
    const { conversationFocusedState } = this.props;
    if (conversationFocusedState && this.messagesEndRef?.current?.scrollIntoView) {
      this.messagesEndRef.current.scrollIntoView({
        behavior: autoBehavior ? 'auto' : 'smooth',
      });
    }
  }

  /**
   * Updates the chat thread height.
   */
  setChatThreadHeight = () => {
    const { settings, parentHeight, parentWidth } = this.props;
    const { threadHeight } = this.state;
    const position = settings.design.position ? settings.design.position : 'Center';
    let newThreadHeight = threadHeight;
    const responsiveMobileWidth = 577;
    const responsiveHeight = 500;
    const typeHeadRelatedHeightValue = 95;
    const windowHeight = parentHeight === null ? window.innerHeight : parentHeight;
    const windowWidth = parentWidth === null ? window.innerWidth : parentWidth;

    if (position === 'Center') {
      // The calculation of the height is:
      // 60% of screen height - ($typeHeadHeight__large + $typeHeadBottom__large)
      if (windowHeight < responsiveHeight) {
        newThreadHeight = windowHeight - typeHeadRelatedHeightValue;
      } else {
        newThreadHeight = (windowHeight * 0.6) - typeHeadRelatedHeightValue;
      }
    } else if (position === 'Right') {
      if (windowWidth < responsiveMobileWidth) {
        if (windowHeight < responsiveHeight) {
          newThreadHeight = windowHeight - typeHeadRelatedHeightValue;
        } else {
          newThreadHeight = (windowHeight * 0.6) - typeHeadRelatedHeightValue;
        }
      } else {
        newThreadHeight = windowHeight - typeHeadRelatedHeightValue;
      }
    }
    this.setState({ threadHeight: newThreadHeight });
  }

  /**
   * Component's main render method - renders the conversation message list
   * @returns {React.Node} Rendered content.
   */
  render() {
    const {
      settings,
      conversation,
      conversationFocusedState,
      ticketFields,
      botId,
      hooks,
      iframeInterface,
      analytics,
    } = this.props;
    const { threadHeight } = this.state;
    let focusedClass = `g-thread--${conversationFocusedState ? 'focused' : 'blurred'}`;
    const threadStyle: any = {
      height: `${threadHeight}px`,
    };
    if (iframeInterface?.inIFrame) {
      focusedClass += ' iframe';
    }
    return (
      <div
        data-testid="chat-thread-container"
        className={`g-thread ${focusedClass}`}
        ref={this.nodeRef}
        style={threadStyle}
      >
        <ScrollContainer
          style={{ overflowY: 'auto' }}
          ignoreElements=".gam-user-message, .gam-system-message"
        >
          <div className="g-thread__inner">
            <Thread
              key={this.state.threadKey}
              messages={conversation}
              settings={settings}
              hooks={{
                ...hooks,
                onButtonClick: (button: ButtonType) => {
                  analytics.track(
                    AnalyticsEvents.VISITOR_CLICKED_BUTTON,
                    { button_type: button.type },
                  );
                  hooks.onButtonClick(button);
                },
                onModalChange: (showing: boolean) => {
                  hooks.onModalChange(showing);
                  if (!showing) {
                    setTimeout(() => {
                      this.scrollToBottom(true);
                    }, 500);
                  }
                },
              }}
              ticketFields={ticketFields}
              botId={botId}
            />
            <div ref={this.messagesEndRef} />
          </div>
        </ScrollContainer>
      </div>
    );
  }
}

export default ChatThread;
