// @flow
import React, { useLayoutEffect } from 'react';

// $flow-disable
import './TypeHeadClassic.scss';

import { type TypeHeadClassicProps } from '../ComponentEntities/componentEntities';

const TypeHeadClassic = (props: TypeHeadClassicProps) => {
  const { message, placeholder, typeheadRef, newMessageCount, widgetOpen } = props;
  useLayoutEffect(() => {
    if (widgetOpen && typeheadRef.current) {
      typeheadRef.current.focus();
    }
  }, [widgetOpen, typeheadRef.current]);

  return (
    <div className="g-typehead-widget" ref={props.nodeRef}>
      {props.renderAutoComplete()}
      <input
        aria-label="type-head-input"
        className="g-typehead-widget__input"
        type="text"
        placeholder={placeholder}
        name="userMessage"
        value={message}
        autoComplete="off"
        ref={typeheadRef}
        onFocus={props.onFocus}
        onChange={props.onChange}
        onKeyUp={props.onKeyUp}
      />
      <div
        className={`widget-input-suffix${message.trim() ? ' __suffx-active' : ''}`}
        onClick={() => props.onKeyUp({ keyCode: 13 })}
      />
      {newMessageCount ? props.updateUnreadMsgNumber(newMessageCount) : null}
      {props.renderAudioMic()}
      <p className="powered-by-widget">
        <span aria-label="powered-by-title" role="img">💡</span>
          &nbsp; Powered by &nbsp;
        <a
          data-testid="gamalon-link"
          target="_blank"
          rel="noopener noreferrer"
          href="https://gamalon.com/"
        >
          Gamalon
        </a>
      </p>
    </div>
  );
};

export default TypeHeadClassic;
