// @flow
import React, { useLayoutEffect } from 'react';
import { MoreOutlined } from '@ant-design/icons';

import TypeHead from '../TypeHead';
import ChatThread from '../ChatThread/ChatThread';
import OptionsWidget from './OptionsWidget';
import ChatTriggerWidget from './ChatTriggerWidget';

// $flow-disable
import { ReactComponent as ChatLogoDefault } from '../../assets/images/chat-logo-default.svg';
// $flow-disable
import './ConversationBaseChatClassic.scss';

import { type ConversationBaseChatClassicProps } from '../ComponentEntities/componentEntities';

const ConversationBaseChatClassic = (props: ConversationBaseChatClassicProps) => {
  useLayoutEffect(() => {
    const appHeight = () => {
      const doc = window.document.documentElement;
      doc.style.setProperty('--chat-classic-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', appHeight);
    appHeight();
    return () => {
      window.removeEventListener('resize', appHeight);
    };
  }, []);

  const readyClass = `g-conversation-base-widget--${props.ready ? 'ready' : ''}`;
  const optionsClass = `g-conversation-base-widget--${props.optionsOpen ? 'options' : ''}`;
  const openedClass = `g-conversation-base-widget--${props.widgetOpen ? 'open' : 'closed'}`;

  return (
    <div className={`g-conversation-base-widget ${openedClass} ${readyClass} ${optionsClass}`}>
      {props.welcomeMessages}
      <div className="chat-widget-container">
        <div className="chat-widget-header">
          <div className="chat-widget-header-group header-title">
            <ChatLogoDefault className="header-company-icon" />
            <span>Gamalon Chat</span>
          </div>
          <div className="chat-widget-header-group header-options">
            <MoreOutlined
              className="header-options-icon"
              onClick={() => {
                props.toggleOptions();
              }}
            />
          </div>
        </div>
        <div className="chat-widget-options">
          <OptionsWidget
            appId={props.appId}
            conversation={props.conversation}
            resetConversation={props.resetConversation}
            settings={props.settings}
            hadConversation={props.hadConversation}
            downloadTranscript={props.downloadTranscript}
          />
        </div>
        <div className="chat-widget-thread">
          <ChatThread
            botId={props.botId}
            settings={props.settings}
            conversation={props.conversation}
            conversationFocusedState
            setChatThreadRef={props.setChatThreadRef}
            ticketFields={props.ticketFields}
            hooks={props.hooks}
            analytics={props.analytics}
          />
        </div>
        <div className="chat-widget-footer">
          <TypeHead
            type="classic"
            appId={props.appId}
            typeheadRef={props.typeheadRef}
            widgetOpen={props.widgetOpen}
            newMessageCount={props.newMessageCount}
            settings={props.settings}
            chatThreadRef={props.chatThreadRef}
            optionsRef={props.optionsRef}
            waitingForSystemResponse={props.waitingForSystemResponse}
            typeaheadSuggestions={props.typeaheadSuggestions}
            conversation={props.conversation}
            hadConversation={props.hadConversation}
            onError={props.onError}
            sendMessage={props.sendUserMessage}
            resetProactivePing={props.resetProactivePing}
            downloadTranscript={props.downloadTranscript}
            resetNewMessageCount={props.resetNewMessageCount}
            placeholder={props.placeholder}
            isLastMessageAQuestion={props.isLastMessageAQuestion}
            typeaheadCatalogSuggestions={props.typeaheadCatalogSuggestions}
            resetConversation={props.resetConversation}
            handleConversationFocusState={props.handleConversationFocusState}
            getTypeHeadCatalogSuggestions={props.getTypeHeadCatalogSuggestions}
            analytics={props.analytics}
          />
        </div>
      </div>
      <ChatTriggerWidget
        widgetOpen={props.widgetOpen}
        companyLogo={props.settings?.design?.logo}
        newMessageCount={props.newMessageCount}
        resetNewMessageCount={props.resetNewMessageCount}
        onActiveTriggerClick={props.onActiveTriggerClick}
        onDefaultTriggerClick={props.onDefaultTriggerClick}
        color={props.settings.design.highlightColor}
      />
      <div
        id="g-no-display"
        ref={props.hiddenSmoochRef}
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default ConversationBaseChatClassic;
