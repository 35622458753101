import uuid4 from 'uuid/v4';
import dayjs from 'dayjs';
import { getIdentity } from '../tracking';

const getUserStorageName = botId => `${botId}--userId`;
const getSessionStorageName = botId => `${botId}--sessionId`;

export const fetchIdentity = (
  organizationId,
  domain,
  botId,
) => new Promise((resolve, reject) => {
  getIdentity(
    organizationId,
    domain,
    botId,
  ).then((identifiedVisitorData) => {
    if (!identifiedVisitorData) {
      reject();
      return;
    }

    const { visitorId, sessionId } = identifiedVisitorData;

    if (visitorId) {
      const userStorageKey = getUserStorageName(botId);
      localStorage.setItem(userStorageKey, visitorId);
    }

    if (sessionId) {
      const sessionStorageKey = getSessionStorageName(botId);
      sessionStorage.setItem(sessionStorageKey, sessionId);
    }

    resolve({ visitorId, sessionId });
  });
});

/**
* @param {number} botId - the bot's id
* @returns {any} object containing the user id and a flag indicating if it's a
* new id
*/
export const getOrCreateUserId = (botId) => {
  const userStorageName = getUserStorageName(botId);
  let visitorId = localStorage.getItem(userStorageName);
  let isNew = false;

  if (!visitorId) {
    visitorId = uuid4();
    localStorage.setItem(userStorageName, visitorId);
    isNew = true;
  }

  return { visitorId, isNew };
};

/**
* @param {number} botId - the bot's id
* @returns {string} session id
*/
export const getSessionId = (botId) => {
  const storageKey = `${botId}--sessionId`;
  let sessionId = sessionStorage.getItem(storageKey);
  let isNew = false;

  if (!sessionId) {
    sessionId = uuid4();
    sessionStorage.setItem(storageKey, sessionId);
    isNew = true;
  }

  return { sessionId, isNew };
};

export const isNotDuplicateLoad = (botId, url, rollbar) => {
  const timestamp = window.sessionStorage.getItem(`${botId}-${url}-page-opened-timestamp`);
  const now = dayjs();
  window.sessionStorage.setItem(`${botId}-${url}-page-opened-timestamp`, now.toString());

  if (timestamp) {
    const prevLoadTime = dayjs(timestamp);
    if (now.isBefore(prevLoadTime.add(2, 'seconds'))) {
      rollbar.warning('duplicate page open event detected');
      return false;
    }
  }

  return true;
};
