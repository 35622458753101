// @flow
import React, { useLayoutEffect } from 'react';

import QuotePulse from '../loaders/QuotePulse';
import Options from '../Options';

// $flow-disable
import './TypeHeadBubble.scss';

import { type TypeHeadBubbleProps } from '../ComponentEntities/componentEntities';

const TypeHeadBubble = (props: TypeHeadBubbleProps) => {
  const {
    widgetOpen,
    message,
    settings,
    newMessageCount,
    waitingForSystemResponse,
    placeholder,
    hadConversation,
    typeheadRef,
    focused,
    downloadTranscript,
    onDefaultTriggerClick,
  } = props;
  useLayoutEffect(() => {
    if (widgetOpen && typeheadRef.current) {
      typeheadRef.current.focus();
    }
  }, [widgetOpen, typeheadRef.current]);

  return (
    <div
      ref={props.nodeRef}
      className={`g-typehead-bubble ${focused ? '--focused' : '--blurred'}`}
    >
      {props.renderAutoComplete()}
      <div
        style={{ cursor: 'pointer' }}
        className="g-typehead__logo-wrap"
        onClick={onDefaultTriggerClick}
      >
        {settings.design.logo ? (
          props.renderCustomAvatar()
        ) : (
          <QuotePulse
            isLoading={waitingForSystemResponse}
            layout={settings.design.position}
            color={settings.design.highlightColor}
          />
        )}
      </div>
      <input
        aria-label="type-head-input"
        className="g-typehead__input"
        type="text"
        placeholder={placeholder}
        name="userMessage"
        value={message}
        autoComplete="off"
        ref={typeheadRef}
        onFocus={() => {
          if (widgetOpen) {
            props.onFocus();
          }
        }}
        onChange={(e) => {
          if (widgetOpen) {
            props.onChange(e);
          }
        }}
        onKeyUp={(e) => {
          if (widgetOpen) {
            props.onKeyUp(e);
          }
        }}
      />
      {!waitingForSystemResponse && widgetOpen && message && (
        <div
          className="g-typehead-send-btn"
          onClick={() => {
            props.onKeyUp({ keyCode: 13 });
          }}
        />
      )}
      {!widgetOpen && newMessageCount ? props.updateUnreadMsgNumber(newMessageCount) : null}
      {widgetOpen && (
        <div className="g-typehead__footer">
          <p className="powered-by">
            &nbsp; Powered by &nbsp;
            <a
              data-testid="gamalon-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://gamalon.com/"
            >
              Gamalon
            </a>
          </p>
          <Options
            settings={settings}
            hadConversation={hadConversation}
            downloadTranscript={downloadTranscript}
          />
        </div>
      )}
    </div>
  );
};

export default TypeHeadBubble;
