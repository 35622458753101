// @flow
import React, { useState } from 'react';
// $flow-disable
import { Rating } from 'react-simple-star-rating';

// $flow-disable
import './ChatRating.scss';

type ChatRatingProps = {
  onRating: (rate: number, feedback: string) => Promise<void> | void,
};

const ChatRating = ({ onRating }: ChatRatingProps) => {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [showFeedbackMessage, setShowFeedbackMessage] = useState(false);
  const [showSubmitionMessage, setShowSubmitionMessage] = useState(false);
  const handleRating = (rate: number) => {
    setRating(rate);
    setShowFeedbackMessage(true);
  };
  const renderRatingContent = () => {
    if (showSubmitionMessage) {
      return (
        <div style={{ height: 180 }} className="chat-rating-feedback">
          <div className="chat-rating-icon" />
          <div className="chat-rating-title">Thanks for the feedback!</div>
        </div>
      );
    }
    if (showFeedbackMessage) {
      return (
        <div className="chat-rating-feedback">
          <div className="chat-rating-title">We’d love to hear why..</div>
          <textarea
            placeholder="Tell us about your experience..."
            className="chat-rating-textarea"
            onChange={({ target }) => setFeedback(target.value)}
          />
          <button
            type="submit"
            className="chat-rating-button"
            onClick={() => {
              setShowSubmitionMessage(true);
              onRating(rating / 20, feedback);
            }}
          >
            Done
          </button>
        </div>
      );
    }
    return (
      <div className="chat-rating-modal">
        <div className="chat-rating-title">How was your chat?</div>
        <Rating onClick={handleRating} ratingValue={rating} />
      </div>
    );
  };
  return (
    <div className="chat-rating">
      <div className="chat-rating-overlay">{renderRatingContent()}</div>
    </div>
  );
};

export default ChatRating;
