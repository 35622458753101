// @flow
export const getBrowser = () => {
  const ua = navigator.userAgent;
  let tem;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i)
    || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: tem[1] || '' };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: 'Opera', version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  tem = ua.match(/version\/(\d+)/i);
  if (tem != null) {
    M.splice(1, 1, tem[1]);
  }
  return `${M[0]} ${M[1]}`;
};

export const getTimestamp = () => {
  const date = new Date();
  return (
    date.getMonth()
    + 1
    + '-'
    + date.getDate()
    + '-'
    + date.getFullYear()
    + ' '
    + date.getHours()
    + ':'
    + date.getMinutes()
    + ':'
    + date.getSeconds()
  );
};

export const isClarifyingQuestion = (msg: any) => msg.buttons && msg.buttons.length;
