// @flow
import React, { useLayoutEffect, useRef, useState } from 'react';
import { ArrowDownOutlined } from '@ant-design/icons';
import * as rudderanalytics from 'rudder-sdk-js';

import TypeHead from '../TypeHead';
import ChatThread from '../ChatThread/ChatThread';
import ChatRating from '../ChatRating/ChatRating';

// $flow-disable
import './ConversationBaseChatBubble.scss';

import { type ConversationBaseChatBubbleProps } from '../ComponentEntities/componentEntities';

const ConversationBaseChatBubble = (props: ConversationBaseChatBubbleProps) => {
  const [chatRatingVisible, setChatRatingVisible] = useState(false);
  const optionsRef = useRef(null);

  useLayoutEffect(() => {
    props.setOptionsRef(optionsRef);
  }, [props.setOptionsRef]);

  useLayoutEffect(() => {
    const appHeight = () => {
      const doc = window.document.documentElement;
      doc.style.setProperty('--chat-expandable-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', appHeight);
    appHeight();
    return () => {
      window.removeEventListener('resize', appHeight);
    };
  }, []);

  const handleRating = (rate: number, feedback: string) => {
    rudderanalytics.track('RATED_CHAT_CONVERSATION', {
      env: process.env.NODE_ENV,
      url: window.location.href,
      organization: props.organizationId,
      botId: props.botId,
      appId: props.appId,
      rating: rate,
      feedback,
    });
    props.onEndChatSession();
    setTimeout(() => {
      setChatRatingVisible(false);
    }, 1700);
  };

  let placementClass = 'g-conversation-base-bubble--right';
  const placementPos = {};
  if (props.settings.design?.placement?.side) {
    // $flow-disable
    const { side, posLeft, posRight, posBottom } = props.settings.design.placement;
    if (side === 'custom') {
      if (posRight) {
        placementClass = 'g-conversation-base-bubble--right';
        placementPos.right = `${posRight}px`;
      } else if (posLeft) {
        placementClass = 'g-conversation-base-bubble--left';
        placementPos.left = `${posLeft}px`;
      }
      if (posBottom) {
        placementPos.bottom = `${posBottom}px`;
      }
    } else {
      placementClass = `g-conversation-base-bubble--${side}`;
    }
  }
  const readyClass = `g-conversation-base-bubble--${props.ready ? 'ready' : ''}`;
  const openedClass = `g-conversation-base-bubble--${props.widgetOpen ? 'open' : 'closed'}`;
  const focusedClass = `g-conversation-base-bubble--${props.focused ? 'focused' : 'blurred'}`;

  return (
    <div
      style={placementPos}
      className={`g-conversation-base-bubble ${openedClass} ${readyClass} ${focusedClass} ${placementClass}`}
    >
      {props.newMessages ? props.newMessages : props.welcomeMessages}
      <div className="chat-bubble-container">
        <div ref={optionsRef} className="chat-bubble-options">
          <div
            className="chat-bubble-option option-end"
            onClick={() => {
              if (props.hadConversation) {
                setChatRatingVisible(true);
              } else {
                props.onActiveTriggerClick();
              }
            }}
          >
            End Chat
          </div>
          <div
            className="chat-bubble-option option-close"
            style={{ marginLeft: 10, borderRadius: '50%', padding: '3.4px 5.3px 0.5px 5.3px' }}
            onClick={props.onActiveTriggerClick}
          >
            <ArrowDownOutlined />
          </div>
        </div>
        <div className="chat-bubble-thread">
          <ChatThread
            userId={props.userId}
            analytics={props.analytics}
            botId={props.botId}
            settings={props.settings}
            conversation={props.conversation}
            conversationFocusedState
            setChatThreadRef={props.setChatThreadRef}
            ticketFields={props.ticketFields}
            hooks={props.hooks}
          />
          {chatRatingVisible && <ChatRating onRating={handleRating} />}
        </div>
        <div className="chat-bubble-footer">
          <TypeHead
            type="expandable"
            appId={props.appId}
            widgetOpen={props.widgetOpen}
            typeheadRef={props.typeheadRef}
            newMessageCount={props.newMessageCount}
            settings={props.settings}
            chatThreadRef={props.chatThreadRef}
            optionsRef={props.optionsRef}
            waitingForSystemResponse={props.waitingForSystemResponse}
            typeaheadSuggestions={props.typeaheadSuggestions}
            conversation={props.conversation}
            hadConversation={props.hadConversation}
            onError={props.onError}
            sendMessage={props.sendUserMessage}
            resetProactivePing={props.resetProactivePing}
            downloadTranscript={props.downloadTranscript}
            resetNewMessageCount={props.resetNewMessageCount}
            placeholder={props.placeholder}
            isLastMessageAQuestion={props.isLastMessageAQuestion}
            typeaheadCatalogSuggestions={props.typeaheadCatalogSuggestions}
            resetConversation={props.resetConversation}
            onDefaultTriggerClick={props.onDefaultTriggerClick}
            handleConversationFocusState={props.handleConversationFocusState}
            getTypeHeadCatalogSuggestions={props.getTypeHeadCatalogSuggestions}
            analytics={props.analytics}
          />
        </div>
      </div>
      <div
        id="g-no-display"
        ref={props.hiddenSmoochRef}
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default ConversationBaseChatBubble;
