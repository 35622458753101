/* eslint-disable */
import Smooch from 'smooch';
import uniqid from 'uniqid';

import { getUserJWT } from './api';

class HeadlessChat {
  constructor() {
    this.initialized = false;
    this.firstMessage = false;
    this.userId = uniqid();
    this.sessionId = uniqid();
    
    this.receivedMessageListeners = [];
    this.initializeListeners = [];
  }

  init(dekuDomain, config) {
    getUserJWT(dekuDomain, this.userId, config.organizationId)
      .then(({ appId, token }) => {
        Smooch.render(document.getElementById('hidden-chat'));
        initChatInstance(appId, this.userId, token)
          .then(() => {
            Smooch.setDelegate({
              beforeSend: (message) => {
                const messageObj = {
                  ...message,
                  metadata: {
                    sessionId: this.sessionId,
                    firstMessage: this.firstMessage,
                  },
                };
                if (this.firstMessage) {
                  this.firstMessage = false;
                }
                return messageObj;
              },
            });
            
            Smooch.on('message:received', (message) => {
              this.receivedMessageListeners.forEach(cb => cb(message));
            });

            this.initialized = true;
            this.initializeListeners.forEach(cb => cb());
          })
          .catch((err) => {
            console.error(err);
            throw ( err );
          });
      });
  }

  send(message) {
    if (this.initialized === false) {
      console.error('Headless mode has not been initialized');
      return;
    }
    
    Smooch.sendMessage(message);
  }
  
  onInitialize(func) {
    if (this.initialized === true) {
      func();
      return;
    }

    this.initializeListeners.push(func);
  }

  onReceive(func) {
    this.receivedMessageListeners.push(func);
  }
  
  removeReceiveListeners() {
    this.receivedMessageListeners = [];
  }
}

export default HeadlessChat;
